import { LoadingButton } from "@mui/lab";
import { Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { currencyCodes } from "@/lib/currencyCodes";

import messages from "./messages";

type Props = {
    readonly disabled?: boolean;
    currency: string;
    onChangeCurrency(newCurrency: string): Promise<void>;
};

export function AdvancedOptions({ disabled, currency, onChangeCurrency }: Props) {
    const { formatMessage } = useIntl();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [updates, setUpdates] = useState<{ currency?: string }>({});

    async function handleUpdateSettings() {
        setLoading(true);
        try {
            if (updates.currency !== undefined) {
                await onChangeCurrency(updates.currency);
            }
        } catch {
            // handling error is covered outside this component, so we'll ignore the error
        } finally {
            setUpdates({});
            setConfirmModalOpen(false);
            setLoading(false);
        }
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h6">{formatMessage(messages.title)}</Typography>
            <Divider />
            <Stack
                spacing={2}
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setConfirmModalOpen(true);
                }}
            >
                <FormControl fullWidth>
                    <InputLabel id="display-currency-select">{formatMessage(messages.displayCurrency)}</InputLabel>
                    <Select
                        labelId="display-currency-select"
                        label={formatMessage(messages.displayCurrency)}
                        onChange={(e) => {
                            setUpdates({ ...updates, currency: e.target.value as string });
                        }}
                        size="small"
                        defaultValue={currency}
                        disabled={disabled}
                    >
                        {currencyCodes.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                                {currency}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Stack direction="row" justifyContent="end">
                    <LoadingButton disabled={disabled} loading={loading} type="submit" variant="contained">
                        {formatMessage(messages.saveButton)}
                    </LoadingButton>
                </Stack>
            </Stack>
            <ConfirmDialog
                title={formatMessage(messages.dialogTitle)}
                description={formatMessage(messages.dialogDescription)}
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
                onConfirm={handleUpdateSettings}
            />
        </Stack>
    );
}
