import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

interface Props {
    open: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    confirmText?: string;
}

export function ConfirmDialog({ open, title, description, onClose, onConfirm, confirmText }: Props) {
    const [loading, setLoading] = useState(false);
    const onConfirmClick = async () => {
        setLoading(true);
        await onConfirm().finally(() => setLoading(false));
    };
    const { formatMessage } = useIntl();
    return (
        <Dialog maxWidth="xs" open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2">{description}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pb: 3, px: 3 }}>
                <Button type="button" variant="text" color="inherit" onClick={onClose}>
                    {formatMessage(messages.cancelButtonText)}
                </Button>
                <LoadingButton
                    data-testid="delete-dialog-confirm-button"
                    variant="contained"
                    loading={loading}
                    onClick={onConfirmClick}
                >
                    {confirmText ?? formatMessage(messages.confirmButtonText)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
