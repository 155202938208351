import { Clipboard } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Snackbar,
    Stack,
    TextField,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

interface Props {
    open: boolean;
    token?: string;
    onClose: () => void;
}

export function CopyTokenDialog({ open, token, onClose }: Props) {
    const { formatMessage } = useIntl();
    const [showMessage, setShowMessage] = useState(false);

    async function copyTokenToClipboard() {
        if (token) {
            if ("clipboard" in navigator) {
                await navigator.clipboard.writeText(token);
                setShowMessage(true);
            }
        }
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={onClose}>
            <DialogTitle>{formatMessage(messages.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2">{formatMessage(messages.description)}</DialogContentText>
                <Stack pt={3} direction="row" spacing={2}>
                    <TextField onFocus={(e) => e.target.select()} sx={{ flexGrow: 1 }} size="small" value={token} />
                    <IconButton onClick={copyTokenToClipboard} size="small">
                        <Clipboard fontSize="small" />
                    </IconButton>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ pb: 3, px: 3 }}>
                <Button type="button" variant="outlined" color="inherit" onClick={onClose}>
                    {formatMessage(messages.closeButton)}
                </Button>
            </DialogActions>

            <Snackbar
                open={showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={formatMessage(messages.copyText)}
            />
        </Dialog>
    );
}
