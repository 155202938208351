import { defineMessages } from "react-intl";

const messages = defineMessages({
    infoOnlyAdministrators: {
        defaultMessage: "Only administrator of this workspace can grant support access.",
    },
    intercomDocumentation: {
        defaultMessage: "Intercom help documentation",
    },
    help: {
        defaultMessage: "For more information, visit our support documentation.",
    },
    colApprovedAt: {
        defaultMessage: "Granted at",
    },
    subtitleDetails: {
        defaultMessage: "Details",
    },
    colConsentId: {
        defaultMessage: "Consent ID",
    },
    colAccessId: {
        defaultMessage: "Access ID",
    },
    colDuration: {
        defaultMessage: "Duration (hours)",
    },
    colConsentedBy: {
        defaultMessage: "Consented by",
    },
    colReason: {
        defaultMessage: "Reason",
    },
    colUser: {
        defaultMessage: "Support user",
    },
    revokedAt: {
        defaultMessage: "Revoked {ts}",
    },
    active: {
        defaultMessage: "Active, expires in {ts}",
    },
    pending: {
        defaultMessage: "Pending, waiting for consent",
    },
    expiresShortly: {
        defaultMessage: "Just expired, will be revoked shortly",
    },
    genericError: {
        defaultMessage: "Something went wrong. The team has been notified",
    },
    columnStatus: {
        defaultMessage: "Status",
    },
    columnApprovedBy: {
        defaultMessage: "Approved by",
    },
    columnApproved: {
        defaultMessage: "Approved",
    },
    historyEmpty: {
        defaultMessage: "No support users have been granted access to your account.",
    },
    unknownUser: {
        defaultMessage: "Unknown user",
    },
    historyTitle: {
        defaultMessage: "Access Logs",
    },
    btnRevoke: {
        defaultMessage: "Revoke Ignite Support Access",
    },
    btnApprove: {
        defaultMessage: "Approve Ignite Support Access",
    },
    labelDuration: {
        defaultMessage: "Expire after",
    },
    title: {
        defaultMessage: "Support Access",
    },
    description: {
        defaultMessage:
            "We may need to access your account to help you with a support request. You can revoke access at any time.",
    },
    expiresAfter: {
        defaultMessage: "This consent automatically expires after {days} {days, plural, one {day} other {days}}.",
    },
    expiresNever: {
        defaultMessage: "This consent will not expire, but you can revoke it at any time.",
    },
    expiresAt: {
        defaultMessage: "This consent expires {ts}.",
    },
    option: {
        defaultMessage: "{days} {days, plural, one {day} other {days}}",
    },
    granted: {
        defaultMessage: "Support access granted",
    },
    revoked: {
        defaultMessage: "Support access revoked",
    },
});

export default messages;
