import {
    MenuItem,
    FormControl,
    FormControlLabel,
    Stack,
    Radio,
    Typography,
    Divider,
    FormControlLabelProps,
    Collapse,
    Select,
    ListSubheader,
    InputLabel,
    Box,
} from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import {
    CONTRACT_ROLES,
    SUPPLIER_ROLES,
    LABELED_ROLES,
    MAIN_ROLES,
    RoleKey,
    mainRoles,
    additionalEditorRoles,
    additionalVieweroles,
} from "@/interfaces/roles";

import { RoleChip } from "../RolesChip";

function hasRole(roles: RoleKey[], ...role: RoleKey[]): boolean {
    return role.some((r) => roles.includes(r));
}
function removeRole(roles: RoleKey[], ...role: RoleKey[]): RoleKey[] {
    const newRoles = roles.filter((r) => !role.includes(r));
    return newRoles;
}
// replace all roles in excludes with key, if there's at least one role in excludes
// also in current.
function keepOneRoleSet(current: RoleKey[], key: RoleKey, set: RoleKey[]): RoleKey[] {
    if (!set.includes(key)) {
        return current;
    }
    return removeRole(current, ...set).concat(key);
}
// removes all roles in excludes, if key is present. Otherwise does nothing
function removeRoleSet(current: RoleKey[], key: RoleKey, excludes: RoleKey[]): RoleKey[] {
    if (!current.some((r) => excludes.includes(r))) {
        return current;
    }
    return removeRole(current, key).concat(excludes);
}

type RowProps = Omit<FormControlLabelProps, "control"> & {
    id: string;
    label: React.ReactElement;
    description: React.ReactElement;
};

const RoleControl: React.FC<RowProps> = ({ id, label, onChange, description, ...props }) => {
    return (
        <FormControlLabel
            {...props}
            control={<Radio />}
            sx={{
                alignItems: "start",
                // preserve aspect ratio for radio
                ".MuiRadio-root": { aspectRatio: "1" },
            }}
            value={id}
            onChange={onChange}
            label={
                <Stack width="100%">
                    <Typography variant="textSm" fontWeight="500">
                        {label}
                    </Typography>
                    <Typography sx={{ whiteSpace: "pre-line" }} variant="textSm">
                        {description}
                    </Typography>
                </Stack>
            }
        />
    );
};

type Props = {
    value: RoleKey[];
    onChange: (value: RoleKey[]) => void;
    disabled?: boolean;
};

function handleRoleChanged(current: RoleKey[], key: RoleKey, checked: boolean): undefined | RoleKey[] {
    let values = [...current];

    if (checked) {
        values = keepOneRoleSet(values, key, mainRoles);
        values = keepOneRoleSet(values, key, ["global.contract-viewer", "global.contract-editor"]);
        values = keepOneRoleSet(values, key, ["global.supplier-viewer", "global.supplier-editor"]);
    } else {
        values = removeRoleSet(values, key, ["global.contract-viewer", "global.contract-editor"]);
        values = removeRoleSet(values, key, ["global.supplier-viewer", "global.supplier-editor"]);
    }
    if (hasRole(values, "global.viewer", "global.editor", "global.admin")) {
        values = removeRole(values, "global.contract-viewer", "global.supplier-viewer");
    }
    if (hasRole(values, "global.editor", "global.admin")) {
        values = removeRole(values, "global.contract-editor", "global.supplier-editor");
    }

    return values;
}

export const RolesMenu: React.FC<Props> = ({ value, onChange, disabled }) => {
    const additionalRolesAvailable = hasRole(value, "global.none", "global.viewer");
    const showAdditionalViewerRoles = hasRole(value, "global.none");

    function showAdditionalRole(role: RoleKey) {
        if (!additionalRolesAvailable) {
            return false;
        }
        if (showAdditionalViewerRoles) {
            return true;
        }
        return additionalEditorRoles.includes(role);
    }

    return (
        <FormControl>
            <InputLabel>
                <FormattedMessage defaultMessage="Choose role(s)" />
            </InputLabel>
            <Select
                label="Choose role(s)"
                fullWidth
                multiple
                value={value}
                autoWidth
                renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((role) => (
                            <RoleChip
                                onDelete={
                                    mainRoles.includes(role) ? undefined : () => onChange(removeRole(value, role))
                                }
                                key={role}
                                role={role}
                                color="neutral"
                            />
                        ))}
                    </Box>
                )}
                MenuProps={{
                    // max height
                    PaperProps: {
                        style: { maxHeight: 400 },
                    },
                }}
            >
                {MAIN_ROLES.map((role) => (
                    <MenuItem key={role.id} value={role.id} sx={{ width: "394px" }}>
                        <RoleControl
                            onChange={(e) => {
                                const ev = e as React.ChangeEvent<HTMLInputElement>;
                                const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                if (updated !== undefined) {
                                    onChange(updated);
                                }
                            }}
                            disabled={disabled}
                            {...role}
                            checked={value.includes(role.id)}
                        />
                    </MenuItem>
                ))}
                <Collapse in={additionalRolesAvailable}>
                    <ListSubheader>
                        <FormattedMessage defaultMessage="Additional Roles" />
                    </ListSubheader>
                </Collapse>
                {CONTRACT_ROLES.map((role) => (
                    <Collapse in={showAdditionalRole(role.id)} key={role.id}>
                        <MenuItem value={role.id}>
                            <RoleControl
                                disabled={disabled}
                                onChange={(e) => {
                                    const ev = e as React.ChangeEvent<HTMLInputElement>;
                                    const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                    if (updated !== undefined) {
                                        onChange(updated);
                                    }
                                }}
                                {...role}
                                checked={value.includes(role.id)}
                            />
                        </MenuItem>
                    </Collapse>
                ))}
                <Collapse in={additionalRolesAvailable}>
                    <Stack py={2} px={3}>
                        <Divider />
                    </Stack>
                </Collapse>
                {SUPPLIER_ROLES.map((role) => (
                    <Collapse in={showAdditionalRole(role.id)} key={role.id}>
                        <MenuItem value={role.id}>
                            <RoleControl
                                disabled={disabled}
                                onChange={(e) => {
                                    const ev = e as React.ChangeEvent<HTMLInputElement>;
                                    const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                    if (updated !== undefined) {
                                        onChange(updated);
                                    }
                                }}
                                {...role}
                                checked={value.includes(role.id)}
                            />
                        </MenuItem>
                    </Collapse>
                ))}
            </Select>
        </FormControl>
    );
};

export const RolesSelect: React.FC<Props> = ({ value, onChange, disabled }) => {
    const additionalRolesAvailable = hasRole(value, "global.none", "global.viewer");
    const showAdditionalViewerRoles = hasRole(value, "global.none");

    return (
        <Stack>
            <FormControl>
                {LABELED_ROLES.filter((r) => mainRoles.includes(r.id)).map((role) => (
                    <Stack py={1.25} key={role.id}>
                        <RoleControl
                            disabled={disabled}
                            onChange={(e) => {
                                const ev = e as React.ChangeEvent<HTMLInputElement>;
                                const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                if (updated !== undefined) {
                                    onChange(updated);
                                }
                            }}
                            {...role}
                            checked={value.includes(role.id)}
                        />
                    </Stack>
                ))}
            </FormControl>
            <Collapse in={additionalRolesAvailable}>
                <Stack py={2} px={1}>
                    <Typography variant="textSm" fontWeight="500" py={1.25}>
                        <FormattedMessage defaultMessage="Additional Roles" />
                    </Typography>
                    <FormControl>
                        {LABELED_ROLES.filter((r) => additionalEditorRoles.includes(r.id)).map((role) => (
                            <Stack py={2} key={role.id}>
                                <RoleControl
                                    disabled={disabled}
                                    onChange={(e) => {
                                        const ev = e as React.ChangeEvent<HTMLInputElement>;
                                        const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                        if (updated !== undefined) {
                                            onChange(updated);
                                        }
                                    }}
                                    {...role}
                                    checked={value.includes(role.id)}
                                />
                            </Stack>
                        ))}
                    </FormControl>
                    {showAdditionalViewerRoles && (
                        <Stack>
                            <Divider />
                        </Stack>
                    )}
                    <Collapse in={showAdditionalViewerRoles}>
                        <FormControl>
                            {LABELED_ROLES.filter((r) => additionalVieweroles.includes(r.id)).map((role) => (
                                <Stack py={2} key={role.id}>
                                    <RoleControl
                                        disabled={disabled}
                                        onChange={(e) => {
                                            const ev = e as React.ChangeEvent<HTMLInputElement>;
                                            const updated = handleRoleChanged(value, role.id, ev.target.checked);
                                            if (updated !== undefined) {
                                                onChange(updated);
                                            }
                                        }}
                                        {...role}
                                        checked={value.includes(role.id)}
                                    />
                                </Stack>
                            ))}
                        </FormControl>
                    </Collapse>
                </Stack>
            </Collapse>
        </Stack>
    );
};
