import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Advanced options",
    },
    saveButton: {
        defaultMessage: "Save",
    },
    displayCurrency: {
        defaultMessage: "Display currency",
    },
    dialogTitle: {
        defaultMessage: "Are you sure?",
    },
    dialogDescription: {
        defaultMessage:
            "This action impacts the global settings for all users in this workspace and it will take some time before the change is reflected in the data.",
    },
});

export default messages;
