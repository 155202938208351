import { ShapeIcon } from "@ignite-analytics/components";
import { Trash, X } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Stack, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
    open: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export function ConfirmRemoveUserDialog({ open, onClose, onConfirm }: Props) {
    const [loading, setLoading] = useState(false);
    const onConfirmClick = async () => {
        setLoading(true);
        await onConfirm().finally(handleClose);
    };
    function handleClose() {
        setLoading(false);
        onClose();
    }
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            sx={{
                display: "relative",
            }}
        >
            <IconButton sx={{ position: "absolute", color: "#94979E", top: 0, right: 0 }} onClick={onClose}>
                <X />
            </IconButton>
            <Stack p={3} alignItems="center" gap={4}>
                <Stack gap={2.5} alignItems="center">
                    <ShapeIcon color="error" icon={<Trash />} />
                </Stack>
                <Stack gap={1} alignItems="center">
                    <Typography variant="textLg" fontWeight="500">
                        <FormattedMessage defaultMessage="Remove from workspace" />
                    </Typography>
                    <Typography variant="textSm" color="textBody" textAlign="center">
                        <FormattedMessage defaultMessage="Are you sure you want to remove the user from this workspace? The action can not be undone." />
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1.5} width="100%" justifyContent="space-between" alignItems="stretch">
                    <Button fullWidth type="button" color="secondary" onClick={handleClose}>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <LoadingButton
                        fullWidth
                        color="error"
                        data-testid="delete-dialog-confirm-button"
                        variant="contained"
                        loading={loading}
                        onClick={onConfirmClick}
                    >
                        <FormattedMessage defaultMessage="Remove user" />
                    </LoadingButton>
                </Stack>
            </Stack>
        </Dialog>
    );
}
