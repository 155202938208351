import { ChevronDown, ChevronUp, InformationCircle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Box,
    Chip,
    Collapse,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { useAlert } from "@/contexts/Alerts";
import { AccessHistory, Consent } from "@/interfaces/support-access";
import { usePermission } from "@/lib/tools";

import { useSupportAccess } from "./hooks";
import { Illustration } from "./illustration";
import messages from "./messages";

type AuditLogProps = {
    accesses: AccessHistory[];
    consents: Consent[];
    totalAccesses: number;
    onPageChange: (page: number) => void;
};
const rowsPerPage = 10;

const AuditLog: React.FC<AuditLogProps> = ({ accesses, consents, totalAccesses, onPageChange }) => {
    const { formatMessage: fm } = useIntl();
    const [page, setPage] = React.useState(0);

    function statusChip(e: AccessHistory) {
        let label: string, color: "success" | "warning" | undefined;
        const expiresAt = dayjs(e.grantedAt).add(e.durationHours, "hour");
        if (e.grantedAt == null) {
            label = fm(messages.pending);
            color = undefined;
        } else if (e.revokedAt) {
            label = fm(messages.revokedAt, { ts: dayjs(e.revokedAt).fromNow() });
            color = undefined;
        } else {
            const now = dayjs();
            if (expiresAt.isAfter(now)) {
                label = fm(messages.active, { ts: expiresAt.fromNow() });
                color = "success";
            } else {
                label = fm(messages.expiresShortly);
                color = "warning";
            }
        }
        return <Chip sx={{ fontSize: "small" }} size="small" label={label} color={color} variant="status" />;
    }

    if (accesses.length === 0) {
        return (
            <Stack alignItems="start" gap={2}>
                <Typography variant="h6">{fm(messages.historyTitle)}</Typography>
                <Typography variant="body2">{fm(messages.historyEmpty)}</Typography>
            </Stack>
        );
    }

    const Row = (row: AccessHistory) => {
        const [open, setOpen] = React.useState(false);
        const consent = consents.find((e) => e.id === row.consentId);
        let consentedByDisplayName: string;
        if (consent) {
            consentedByDisplayName = consent.approvedBy.displayName;
        } else {
            consentedByDisplayName = fm(messages.unknownUser);
        }
        return (
            <React.Fragment>
                <TableRow sx={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small">
                            {open ? <ChevronUp /> : <ChevronDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.user.displayName}
                    </TableCell>
                    <TableCell>{row.reason}</TableCell>
                    <TableCell align="right">{statusChip(row)}</TableCell>
                </TableRow>
                <TableRow hover={false}>
                    <TableCell
                        colSpan={5}
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                        }}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box>
                                <Typography variant="subtitle1" gutterBottom component="div">
                                    {fm(messages.subtitleDetails)}
                                </Typography>

                                <Table size="small" aria-label="details">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ pl: 0 }}>{fm(messages.colApprovedAt)}</TableCell>
                                            <TableCell>{dayjs(row.grantedAt).format("DD MMM YYYY, HH:mm")}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{fm(messages.colConsentedBy)}</TableCell>
                                            <TableCell>{consentedByDisplayName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{fm(messages.colDuration)}</TableCell>
                                            <TableCell>{row.durationHours}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{fm(messages.colAccessId)}</TableCell>
                                            <TableCell>{row.id}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{fm(messages.colConsentId)}</TableCell>
                                            <TableCell>{row.consentId}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    // columns I want: person, timestamp, hours, reason, revokedAt / status
    return (
        <Stack alignItems="start" gap={2}>
            <Typography variant="h6">{fm(messages.historyTitle)}</Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{fm(messages.colUser)} </TableCell>
                            <TableCell>{fm(messages.colReason)}</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accesses.map((e) => (
                            <Row key={e.id} {...e} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{ alignSelf: "flex-end" }}
                component="div"
                count={totalAccesses}
                page={page}
                rowsPerPageOptions={[]}
                onPageChange={(_, offset) => {
                    setPage(offset);
                    onPageChange(offset);
                }}
                rowsPerPage={rowsPerPage}
            />
        </Stack>
    );
};

export const SupportAccessPage: React.FC = () => {
    const { grant, revoke, accesses, consents, loading, error, fetchPage, totalAccesses } =
        useSupportAccess(rowsPerPage);
    const { formatMessage: fm } = useIntl();
    const canAdministrate = usePermission("customers", { object: "general", relation: "write" });

    const enabled = useMemo(() => consents.some((h) => h.revokedAt === null), [consents]);
    const [days, setDays] = React.useState<number>(30);
    const { alertUser } = useAlert();

    useEffect(() => {
        if (!error) return;
        error.graphQLErrors.forEach((e) => {
            Sentry.captureException(e, { tags: { app: "company-settings" } });
        });
        error.clientErrors.forEach((e) => {
            Sentry.captureException(e, { tags: { app: "company-settings" } });
        });
    }, [error]);

    return (
        <Container disableGutters={false} sx={{ mx: 0 }} maxWidth="lg">
            {error && <Alert severity="error">{fm(messages.genericError)}</Alert>}
            <Stack py={2} gap={4}>
                {canAdministrate === false && <Alert severity="info">{fm(messages.infoOnlyAdministrators)}</Alert>}
                <Stack direction="row" gap={2} sx={{ borderBottom: "1px solid #e0e0e0" }} alignItems="end">
                    <Stack py={2} gap={4}>
                        <Stack direction="row" gap={1}>
                            <Typography variant="h6">{fm(messages.title)}</Typography>
                            <Link
                                href="https://help-eu.igniteprocurement.com/en/articles/92414-support-access"
                                target="_blank"
                                rel="noopener"
                            >
                                <Tooltip color="secondary" title={fm(messages.help)} leaveDelay={800}>
                                    <InformationCircle fontSize="small" />
                                </Tooltip>
                            </Link>
                        </Stack>
                        <Typography variant="body1">{fm(messages.description, { days })}</Typography>
                        {!enabled && (
                            <Typography variant="body1">
                                {days == -1 ? fm(messages.expiresNever) : fm(messages.expiresAfter, { days: days })}
                            </Typography>
                        )}

                        <Stack alignItems="start" justifyItems="end" height={128} gap={3}>
                            {!enabled ? (
                                <>
                                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                                        <InputLabel>{fm(messages.labelDuration)}</InputLabel>
                                        <Select
                                            value={days}
                                            onChange={(e) => setDays(e.target.value as number)}
                                            label="duration"
                                        >
                                            <MenuItem value={1}>{fm(messages.option, { days: 1 })}</MenuItem>
                                            <MenuItem value={7}>{fm(messages.option, { days: 7 })}</MenuItem>
                                            <MenuItem value={30}>{fm(messages.option, { days: 30 })} </MenuItem>
                                            <MenuItem value={180}>{fm(messages.option, { days: 180 })} </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <LoadingButton
                                        sx={{ mt: "auto", minWidth: 240 }}
                                        disabled={!canAdministrate}
                                        variant="contained"
                                        color="primary"
                                        loading={canAdministrate && loading}
                                        onClick={() =>
                                            grant(days).then(() => {
                                                alertUser({ value: fm(messages.granted), severity: "success" });
                                                track("support access consent: granted", { days });
                                            })
                                        }
                                    >
                                        {fm(messages.btnApprove)}
                                    </LoadingButton>
                                </>
                            ) : (
                                <>
                                    <Typography color="success.dark" variant="body1">
                                        {consents[0].expiresAt == null
                                            ? fm(messages.expiresNever)
                                            : fm(messages.expiresAt, {
                                                  ts: dayjs(consents[0].expiresAt).format("DD MMM YYYY, HH:mm"),
                                              })}
                                    </Typography>

                                    <LoadingButton
                                        sx={{ mt: "auto", minWidth: 240 }}
                                        variant="outlined"
                                        color="error"
                                        disabled={!canAdministrate}
                                        loading={loading}
                                        onClick={() =>
                                            revoke().then(() => {
                                                alertUser({ value: fm(messages.revoked), severity: "success" });
                                                track("support access consent: revoked");
                                            })
                                        }
                                    >
                                        {fm(messages.btnRevoke)}
                                    </LoadingButton>
                                </>
                            )}
                        </Stack>
                    </Stack>
                    <Stack>
                        <Illustration />
                    </Stack>
                </Stack>
                <AuditLog
                    accesses={accesses}
                    consents={consents}
                    totalAccesses={totalAccesses}
                    onPageChange={(p) => fetchPage(p)}
                />
            </Stack>
        </Container>
    );
};
