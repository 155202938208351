import { defineMessages } from "react-intl";

const messages = defineMessages({
    tabInvites: {
        defaultMessage: "Invites",
    },
    tabUsers: {
        defaultMessage: "Users",
    },
    title: {
        defaultMessage: "Users",
    },
    unsavedChanges: {
        defaultMessage: "You have unsaved changes. Are you sure you want to leave?",
    },
    inviteUserButton: {
        defaultMessage: "Invite user",
    },
});

export default messages;
