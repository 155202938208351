import { X } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RolesMenu } from "@/components/RolesSelect";
import { SparseUserInvite } from "@/interfaces/invite";
import { RoleKey } from "@/interfaces/roles";

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: SparseUserInvite) => Promise<void>;
}

export function InviteDialog({ open, onClose, onSubmit }: Props) {
    const [roles, setRoles] = useState<RoleKey[]>(["global.editor"]);
    const [loading, setLoading] = useState(false);
    const { formatMessage } = useIntl();

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = {
            email: formData.get("email") as string,
            roles,
        };
        setLoading(true);
        onSubmit(data).finally(() => {
            setLoading(false);
            onClose();
        });
    }

    return (
        <Dialog
            maxWidth="md"
            open={open}
            onClose={onClose}
            sx={{
                display: "relative",
            }}
        >
            <IconButton sx={{ position: "absolute", color: "#94979E", top: 0, right: 0 }} onClick={onClose}>
                <X />
            </IconButton>

            <Stack component="form" onSubmit={handleSubmit} width="416px">
                <DialogTitle>
                    <FormattedMessage defaultMessage="Invite users" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="body2">
                        <FormattedMessage defaultMessage="Invite users to collaborate on this workspace" />
                    </DialogContentText>
                    <Stack pt={2} spacing={2}>
                        <Stack spacing={2}>
                            <TextField
                                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                                data-testid="invite-email-input"
                                placeholder={formatMessage({ defaultMessage: "Enter user email" })}
                                type="email"
                                name="email"
                                label={<FormattedMessage defaultMessage="Email" />}
                                fullWidth
                            />
                            <RolesMenu data-testid="invite-user-role" onChange={setRoles} value={roles} />
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ pb: 3, px: 3 }}>
                    <Stack direction="row" gap={1.5} width="100%" justifyContent="space-between" alignItems="stretch">
                        <Button size="small" fullWidth type="button" color="secondary" variant="text" onClick={onClose}>
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <LoadingButton
                            size="small"
                            fullWidth
                            disabled={roles.length === 0}
                            data-testid="invite-submit-button"
                            variant="contained"
                            loading={loading}
                            type="submit"
                        >
                            <FormattedMessage defaultMessage="Invite" />
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}
