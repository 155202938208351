import { useEffect } from "react";
import { useIntl } from "react-intl";

import { useBasePath } from "@/contexts/BasePathContext";
import breadcrumbMessages from "@/routes/messages";

export function useSetBreadcrumbs(title: string) {
    const { formatMessage } = useIntl();
    const path = useBasePath();

    useEffect(() => {
        const data = {
            source: "ignite-breadcrumbs",
            payload: [
                { text: formatMessage(breadcrumbMessages.breadcrumbHeader), href: `${path}/general/` },
                { text: title },
            ],
        };
        window.postMessage(data, window.location.origin);
    }, [title, path, formatMessage]);
}
