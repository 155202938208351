import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Create token",
    },
    description: {
        defaultMessage:
            "When creating a new API token the token will be displayed one single time for you to copy it and store it somewhere safe.",
    },

    nameLabel: {
        defaultMessage: "Name",
    },

    roleLabel: {
        defaultMessage: "Role",
    },

    expiryLabel: {
        defaultMessage: "Expires at",
    },

    cancelButton: {
        defaultMessage: "Cancel",
    },

    createButton: {
        defaultMessage: "Create",
    },
});

export default messages;
