import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

interface Props {
    open: boolean;
    loading: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onDelete: () => void;
    confirmationText?: string;
}

export function DeleteDialog({ open, loading, title, description, confirmationText, onClose, onDelete }: Props) {
    const { formatMessage } = useIntl();

    const [text, setText] = useState("");
    const disabled = useMemo(() => {
        if (confirmationText == undefined) return false;
        if (text === confirmationText) return false;
        return true;
    }, [confirmationText, text]);

    function handleClose() {
        setText("");
        onClose();
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText variant="body2">{description}</DialogContentText>
            </DialogContent>
            {confirmationText && (
                <DialogContent>
                    <DialogContentText pb={1} variant="body2">
                        {formatMessage(messages.confirmationText, { confirmationText })}
                    </DialogContentText>
                    <TextField fullWidth variant="outlined" onChange={(e) => setText(e.target.value)} />
                </DialogContent>
            )}
            <DialogActions sx={{ pb: 3, px: 3 }}>
                <Button type="button" variant="text" color="inherit" onClick={handleClose}>
                    {formatMessage(messages.cancelButtonText)}
                </Button>
                <LoadingButton
                    disabled={disabled}
                    data-testid="delete-dialog-confirm-button"
                    variant="contained"
                    color="error"
                    loading={loading}
                    onClick={onDelete}
                >
                    {formatMessage(messages.deleteButtonText)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
