import { ApolloProvider } from "@apollo/client";
import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AlertSnackBar } from "@/components/AlertSnackBar";
import { AlertProvider } from "@/contexts/Alerts";
import { BasePathProvider } from "@/contexts/BasePathContext";
import { useCurrentUser } from "@/hooks/users/hooks";
import { ApiTokensPage } from "@/pages/ApiTokens";
import { ColorsPage } from "@/pages/Colors";
import { GeneralPage } from "@/pages/General";
import { SupportAccessPage } from "@/pages/SupportAccess";
import { UsersPage } from "@/pages/Users";
import { AppRouter } from "@/routes";

import { createGraphqlClient } from "./graphqlClient";
import { getMessages } from "./lib/i18n/compiled";
import { MicroAppMountValues } from "./types";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_TOKEN ?? "");

const App: React.FC<MicroAppMountValues> = ({ locale = "en-US", theme = "ignite-riddle", path }) => {
    const currentPath = path ?? "/";

    const router = createBrowserRouter([
        {
            path: `/`,
            element: <AppRouter />,
            children: [
                {
                    path: `${currentPath}general/`,
                    element: <GeneralPage />,
                },
                {
                    path: `${currentPath}colors/`,
                    element: <ColorsPage />,
                },
                {
                    path: `${currentPath}api-tokens/`,
                    element: <ApiTokensPage />,
                },
                {
                    path: `${currentPath}users/`,
                    element: <UsersPage />,
                },
                {
                    path: `${currentPath}support-access/`,
                    element: <SupportAccessPage />,
                },
            ],
        },
    ]);

    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);

    // globally set the locale for dayjs. Assumes that the locale is same across the app
    dayjs.locale(locale);
    const user = useCurrentUser();
    const tenant = useMemo(() => localStorage.getItem("tenant") || user.tenant || "", [user.tenant]);
    const apolloClient = useMemo(
        () => createGraphqlClient(import.meta.env.VITE_GRAPHQL_GATEWAY_URL || "", tenant),
        [tenant]
    );

    return (
        <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={responsiveFontSizes(createTheme(designTokens))}>
                <CssBaseline />
                <FeatureToggleContextProvider userEmail={user.email ?? ""} tenant={user.tenant ?? ""}>
                    <IntlProvider locale={locale} messages={getMessages(locale)} key={locale} defaultLocale="en-US">
                        <AlertProvider>
                            <BasePathProvider path={currentPath}>
                                <RouterProvider router={router} />
                                <AlertSnackBar />
                            </BasePathProvider>
                        </AlertProvider>
                    </IntlProvider>
                </FeatureToggleContextProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default App;
