import { LoadingButton } from "@mui/lab";
import { Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import { DeleteDialog } from "@/components/DeleteDialog";

import messages from "./messages";

interface Props {
    disabled?: boolean;
    onConfirm: () => Promise<void>;
    confirmationText: string;
}

export function DangerZone({ disabled, onConfirm, confirmationText }: Props) {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <Stack spacing={2}>
            <Typography color="error" variant="h6">
                {formatMessage(messages.title)}
            </Typography>
            <Divider />
            <Stack
                border="1px solid"
                borderColor={(theme) => theme.palette.error.main}
                p={2}
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack maxWidth={350}>
                    <Typography color="error" variant="subtitle1">
                        {formatMessage(messages.deleteWorkspaceTitle)}
                    </Typography>
                    <Typography variant="body2">{formatMessage(messages.deleteWorkspaceDescription)}</Typography>
                </Stack>
                <LoadingButton onClick={() => setOpen(true)} disabled={disabled} variant="contained" color="error">
                    {formatMessage(messages.deleteWorkspaceButton)}
                </LoadingButton>
            </Stack>
            <DeleteDialog
                open={open}
                loading={loading}
                title={formatMessage(messages.dialogTitle)}
                description={formatMessage(messages.dialogDescription)}
                confirmationText={confirmationText}
                onDelete={() => {
                    setLoading(true);
                    onConfirm().finally(() => {
                        setLoading(false);
                        setOpen(false);
                    });
                }}
                onClose={() => setOpen(false)}
            />
        </Stack>
    );
}
