import * as Sentry from "@sentry/react";

export async function logout() {
    const ory = import.meta.env.VITE_ORY_API_URL;
    const logoutUrl = `${ory}/self-service/logout/browser`;
    const v = await fetch(logoutUrl, { headers: { accept: "application/json" }, credentials: "include" });
    if (v.status !== 200) {
        const json = await v.json();
        Sentry.captureMessage("failed to initiate logout flow", {
            tags: { code: v.status, app: "company-settings" },
            extra: json,
        });
        return;
    }
    const data = (await v.json()) as { logout_token: string };
    const v2 = await fetch(`${ory}/self-service/logout?token=${data.logout_token}`);
    if (v2.status > 299) {
        Sentry.captureMessage("failed to complete logout flow", { tags: { code: v2.status, app: "company-settings" } });
    }
    window.location.href = import.meta.env.VITE_LOGIN_URL || "/";
}
