import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/de";
import "dayjs/locale/nb";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { setupProxyAuth } from "./setupProxyAuth";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const useMockServer = import.meta.env.VITE_INITIALIZE_MOCK;

const isProxyToProductionBackend = import.meta.env.VITE_PROXY_WEB_TO_PRODUCTION === "true";

async function main() {
    if (isProxyToProductionBackend) {
        await setupProxyAuth();
    }

    if (useMockServer) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { worker } = await import("./mocks/browser");
        localStorage.setItem("tenant", import.meta.env.VITE_DEFAULT_TENANT || "");
        localStorage.setItem("token", import.meta.env.VITE_DEFAULT_TOKEN || "");
        await worker.start();
    }

    window.renderCompanySettings = (containerId, props) => {
        ReactDOM.render(<App {...props} />, document.getElementById(containerId));
    };

    // Unmounts the component
    window.unmountCompanySettings = (containerId) => {
        const node = document.getElementById(containerId);
        node && ReactDOM.unmountComponentAtNode(node);
    };

    if (!document.getElementById("CompanySettings-container")) {
        ReactDOM.render(<App />, document.getElementById("root"));
    }
}

main();
