import { ApolloError } from "@apollo/client";

export function mapQueryError(error?: ApolloError) {
    if (!error) {
        return "genericError";
    }
    for (const gqlError of error.graphQLErrors) {
        const code = gqlError.extensions?.code;
        if (!code) {
            continue;
        }
        if (code === "USER_EXISTS") {
            return "userAlreadyBelongsToCustomer";
        }
        if (code === "ALREADY_EXISTS") {
            return "inviteAlreadyExists";
        }
        if (code === "RATE_LIMIT_EXCEEDED") {
            return "rateLimitExceeded";
        }
    }

    if (error.message == "Expired authentication token") {
        return "tokenExpired";
    }
    return "genericError";
}
