import { useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";

import { SparseUserInvite, UserInvite } from "@/interfaces/invite";

import {
    deleteInvitesMutation,
    editRolesMutation,
    getInvitesQuery,
    inviteUserMutation,
    resendInvitesMutation,
} from "./queries";

export function useInvites() {
    const {
        data,
        loading: dataLoading,
        refetch,
    } = useQuery<{ getInvites: { result: UserInvite[] } }>(getInvitesQuery, {
        variables: { input: {} },
    });
    const [searchTerm, setSearchTerm] = useState("");

    const [mutInviteUser, inviteResult] = useMutation<{
        inviteUser: { invite: UserInvite };
    }>(inviteUserMutation, { refetchQueries: [{ query: getInvitesQuery }] });
    const [mutResendInvites, resendResult] = useMutation<{ resendInvites: { ids: string[] } }>(resendInvitesMutation);
    const [mutDeleteInvites, deleteResult] = useMutation<{ deleteInvites: { ids: string[] } }>(deleteInvitesMutation);
    const [mutEditRoles, editRolesResult] = useMutation<{ editRoles: { invite: UserInvite } }>(editRolesMutation);

    const error = useMemo(() => {
        return inviteResult.error || deleteResult.error || editRolesResult.error;
    }, [inviteResult.error, deleteResult.error, editRolesResult.error]);
    const loading = useMemo(() => {
        return inviteResult.loading || deleteResult.loading || editRolesResult.loading;
    }, [inviteResult.loading, deleteResult.loading, editRolesResult.loading]);

    async function inviteUser({
        email,
        roles,
    }: SparseUserInvite): Promise<{ error: null; invite: UserInvite } | { error: string }> {
        const res = await mutInviteUser({ variables: { input: { email, roles } } });
        if (res.errors != undefined) {
            Sentry.captureException(res.errors, { tags: { app: "company-settings" } });
            return { error: "An error occurred while sending the invite." };
        } else if (!res.data) {
            return { error: "An error occurred while sending the invite." };
        }
        const { invite } = res.data?.inviteUser;
        setInvites((invites) => [invite, ...invites]);

        return { error: null, invite };
    }

    async function editRoles(inviteId: string, roles: string[]) {
        try {
            await mutEditRoles({ variables: { input: { id: inviteId, roles } } });
            return true;
        } catch (e) {
            console.log("err");
            return false;
        }
    }

    async function resendInvite(invitationId: string): Promise<boolean> {
        try {
            await mutResendInvites({ variables: { input: { ids: [invitationId] } } });
            await refetch();
            return true;
        } catch (e) {
            return false;
        }
    }

    async function deleteInvite(invitationId: string) {
        try {
            await mutDeleteInvites({ variables: { input: { ids: [invitationId] } } });
            await refetch();
            return true;
        } catch (e) {
            return false;
        }
    }

    const [invites, setInvites] = useState<UserInvite[]>([]);
    useEffect(() => {
        const updated = data?.getInvites.result || [];
        if (searchTerm != "") {
            setInvites(updated.filter((inv) => inv.email.toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setInvites(updated);
        }
    }, [data, searchTerm]);

    return {
        invites,
        loading,
        searchTerm: { value: searchTerm, set: setSearchTerm },
        inviteUser,
        deleteInvite,
        resendInvite,
        editRoles,
        dataLoading,
        error,
        reset: () => {
            inviteResult.reset();
            resendResult.reset();
            deleteResult.reset();
            editRolesResult.reset();
        },
    };
}
