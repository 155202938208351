import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Danger zone",
    },
    deleteWorkspaceTitle: {
        defaultMessage: "Delete your workspace",
    },
    deleteWorkspaceDescription: {
        defaultMessage:
            "Deleting your workspace will immediately disable and delete all data related to this workspace. This action is irreversible.",
    },
    deleteWorkspaceButton: {
        defaultMessage: "Delete workspace",
    },

    dialogTitle: {
        defaultMessage: "Important! This is a very dangerous action.",
    },
    dialogDescription: {
        defaultMessage:
            "This action will delete your workspace and all your data and is irreversible. Please proceed with caution.",
    },
});

export default messages;
