import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { FormEvent, useState } from "react";
import { useIntl } from "react-intl";

import { RolesMenu } from "@/components/RolesSelect";
import { RoleKey } from "@/interfaces/roles";
import { ApiTokenInput } from "@/interfaces/tokens";

import messages from "./messages";

interface Props {
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onSubmit: (data: ApiTokenInput) => void;
}

export function CreateDialog({ open, loading, onClose, onSubmit }: Props) {
    const { formatMessage } = useIntl();
    const [roles, setRoles] = useState<RoleKey[]>(["global.editor"]);

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = {
            name: formData.get("name") as string,
            roles,
            deadline: dayjs(formData.get("deadline") as string).toISOString(),
        };
        onSubmit(data);
    }

    return (
        <Dialog maxWidth="xs" open={open} onClose={onClose}>
            <Stack component="form" onSubmit={handleSubmit}>
                <DialogTitle>{formatMessage(messages.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="body2">{formatMessage(messages.description)}</DialogContentText>
                    <Stack pt={2} spacing={2}>
                        <TextField name="name" label={formatMessage(messages.nameLabel)} fullWidth size="small" />
                        <RolesMenu data-testid="invite-user-role" value={roles} onChange={setRoles} />
                        <TextField
                            name="deadline"
                            type="date"
                            label={formatMessage(messages.expiryLabel)}
                            fullWidth
                            size="small"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ pb: 3, px: 3 }}>
                    <Button type="button" variant="outlined" color="inherit" onClick={onClose}>
                        {formatMessage(messages.cancelButton)}
                    </Button>
                    <LoadingButton variant="contained" loading={loading} type="submit">
                        {formatMessage(messages.createButton)}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}
