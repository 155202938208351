import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "API Tokens",
    },
    subTitle: {
        defaultMessage: "Manage your tokens",
    },
    description: {
        defaultMessage: "Api tokens are used to control access to the external apis of Ignite Procurement.",
    },
    error: {
        defaultMessage: "Wops! Something went wrong. Our team has been notified.",
    },

    noTokens: {
        defaultMessage: "There are currently no tokens",
    },

    createButton: {
        defaultMessage: "Create new api token",
    },

    never: {
        defaultMessage: "Never",
    },

    active: {
        defaultMessage: "Currently active, click to disable",
    },

    inactive: {
        defaultMessage: "Currently inactive, click to activate",
    },

    nameHeader: {
        defaultMessage: "Name",
    },

    secretHeader: {
        defaultMessage: "Secret",
    },

    rolesHeader: {
        defaultMessage: "Roles",
    },

    lastUsedHeader: {
        defaultMessage: "Last used",
    },
    createdHeader: {
        defaultMessage: "Created",
    },

    expiringInHeader: {
        defaultMessage: "Expiring in",
    },

    enabledHeader: {
        defaultMessage: "Enabled",
        id: "enabledHeader",
    },

    deleteHeader: {
        defaultMessage: "Delete?",
    },

    deleteTitle: {
        defaultMessage: "Are you sure you want to proceed?",
    },
    deleteDescription: {
        defaultMessage:
            "By removing the token systems using the token to authenticate will no longer have access. It is recommended to first disable the token before deleting it in order to revert the changes quickly.",
    },
});

export default messages;
