import { FormattedMessage } from "react-intl";
export const ALL_ROLES = [
    "global.admin",
    "global.editor",
    "global.viewer",
    "global.supplier-editor",
    "global.contract-editor",
    "global.supplier-viewer",
    "global.contract-viewer",
    "global.none",
] as const;
// keys of ALL_ROLES
export type RoleKey = typeof ALL_ROLES[number];

export const mainRoles: RoleKey[] = ["global.admin", "global.editor", "global.viewer", "global.none"];

export const additionalEditorRoles: RoleKey[] = ["global.supplier-editor", "global.contract-editor"];
export const additionalVieweroles: RoleKey[] = ["global.supplier-viewer", "global.contract-viewer"];

export type Role = {
    id: RoleKey;
    label: React.ReactElement;
    description: React.ReactElement;
};

export const LABELED_ROLES: Role[] = [
    {
        id: "global.admin",
        label: <FormattedMessage defaultMessage="Admin" />,
        description: <FormattedMessage defaultMessage="Can view and edit anything, manage users and change settings" />,
    },
    {
        id: "global.editor",
        label: <FormattedMessage defaultMessage="Editor" />,
        description: (
            <FormattedMessage defaultMessage="Can view and edit anything, but can’t manage users and settings." />
        ),
    },
    {
        id: "global.viewer",
        label: <FormattedMessage defaultMessage="Viewer" />,
        description: <FormattedMessage defaultMessage="Can view anything, but has no editing capabilities" />,
    },
    {
        id: "global.supplier-editor",
        label: <FormattedMessage defaultMessage="Supplier editor" />,
        description: <FormattedMessage defaultMessage="Can edit and view supplier details" />,
    },
    {
        id: "global.contract-editor",
        label: <FormattedMessage defaultMessage="Contract editor" />,
        description: <FormattedMessage defaultMessage="Can edit and view contract details" />,
    },
    {
        id: "global.supplier-viewer",
        label: <FormattedMessage defaultMessage="Supplier viewer" />,
        description: <FormattedMessage defaultMessage="Can view supplier details" />,
    },
    {
        id: "global.contract-viewer",
        label: <FormattedMessage defaultMessage="Contract viewer" />,
        description: <FormattedMessage defaultMessage="Can view contract details" />,
    },
    {
        id: "global.none",
        label: <FormattedMessage defaultMessage="Other" />,
        description: <FormattedMessage defaultMessage="Could be used in conjunction with additional roles" />,
    },
];
export const MAIN_ROLES = LABELED_ROLES.filter((role) =>
    ["global.admin", "global.editor", "global.viewer", "global.none"].includes(role.id)
);
export const SUPPLIER_ROLES = LABELED_ROLES.filter((role) =>
    ["global.supplier-editor", "global.supplier-viewer"].includes(role.id)
);
export const CONTRACT_ROLES = LABELED_ROLES.filter((role) =>
    ["global.contract-editor", "global.contract-viewer"].includes(role.id)
);
