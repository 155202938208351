import { useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Checkbox, CircularProgress, Container, Stack, TextField, Tooltip, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { FormEvent, useState } from "react";
import { useIntl } from "react-intl";

import { useAlert } from "@/contexts/Alerts";
import { UpdateCustomerInput } from "@/gql/graphql";
import { deleteCustomerMutation, getCurrentCustomerQuery, updateCustomerMutation } from "@/hooks/general/queries";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/tools";

import { AdvancedOptions } from "./AdvancedOptions";
import { CustomerLogo } from "./CustomerLogo";
import { DangerZone } from "./DangerZone";
import { logout } from "./helpers";
import messages from "./messages";

export function GeneralPage() {
    const { data } = useQuery(getCurrentCustomerQuery);
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const customer = data?.getCurrentCustomer;
    const [deleteCustomer] = useMutation(deleteCustomerMutation, { onCompleted: logout });
    const [updates, setUpdates] = useState<UpdateCustomerInput>({});
    const canEdit = usePermission("customers", { object: "general", relation: "write" });
    useSetBreadcrumbs(formatMessage(messages.title)); // Update breadcrumbs in base app

    function onSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (updates && Object.keys(updates).length > 0) {
            updateCustomer({ variables: { input: updates } });
        }
    }

    const [updateCustomer, { loading: updating }] = useMutation(updateCustomerMutation, {
        refetchQueries: [{ query: getCurrentCustomerQuery }],
        onCompleted: () => {
            track("company-settings: updated");
            alertUser({ value: formatMessage(messages.settingsUpdated), severity: "success" });
        },
        onError: (e) => {
            Sentry.captureMessage("Failed to update customer settings", {
                tags: { app: "company-settings" },
                extra: { errors: e.toString() },
            });
            alertUser({ value: formatMessage(messages.settingsUpdateFailure), severity: "error" });
        },
    });

    return (
        <Container disableGutters={false} sx={{ mx: 0 }} maxWidth="sm">
            <Stack py={2}>
                <Typography variant="h6">{formatMessage(messages.subTitle)}</Typography>
                {!customer && (
                    <Stack p={3} direction="row" justifyContent="center">
                        <CircularProgress />
                    </Stack>
                )}
                {customer && (
                    <>
                        <Stack onSubmit={onSubmit} component="form" py={2} spacing={3}>
                            <Stack sx={{ pointerEvents: canEdit ? "auto" : "none" }} direction="row">
                                <CustomerLogo customer={{ logo: data.getCurrentCustomer.logo || "" }} />
                            </Stack>

                            <TextField
                                disabled={!canEdit}
                                defaultValue={customer.name}
                                onChange={(e) => setUpdates({ ...updates, name: e.target.value })}
                                size="small"
                                label={formatMessage(messages.labelName)}
                            />
                            <TextField
                                disabled={true}
                                defaultValue={customer.identifier}
                                size="small"
                                label={formatMessage(messages.labelIdentifier)}
                            />
                            <Stack
                                border="1px solid"
                                borderColor={(theme) => theme.palette.divider}
                                p={2}
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Stack>
                                    <span style={{ cursor: canEdit ? "pointer" : "not-allowed" }}>
                                        <Checkbox
                                            disabled={!canEdit}
                                            defaultChecked={customer.requireSSOLogin}
                                            onChange={(e) =>
                                                setUpdates({ ...updates, requireSSOLogin: e.target.checked })
                                            }
                                            inputProps={{ "aria-label": "Enforce SSO checkbox" }}
                                        />
                                    </span>
                                </Stack>
                                <Stack>
                                    <Typography variant="subtitle1">
                                        {formatMessage(messages.enforceSSOTitle)}
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatMessage(messages.enforceSSODescription)}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end">
                                <Tooltip title={canEdit ? "" : formatMessage(messages.noPermissions)}>
                                    <span>
                                        <LoadingButton
                                            disabled={!canEdit}
                                            variant="contained"
                                            loading={updating}
                                            type="submit"
                                        >
                                            {formatMessage(messages.saveButton)}
                                        </LoadingButton>
                                    </span>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        <AdvancedOptions
                            currency={customer?.currency}
                            onChangeCurrency={async (currency: string) => {
                                track("company-settings: currency changed", { currency });
                                await updateCustomer({ variables: { input: { currency } } });
                            }}
                            disabled={!canEdit}
                        />
                        <DangerZone
                            confirmationText={customer.identifier}
                            disabled={!canEdit}
                            onConfirm={async () => {
                                await deleteCustomer();
                            }}
                        />
                    </>
                )}
            </Stack>
        </Container>
    );
}
