import { defineMessages } from "react-intl";

const messages = defineMessages({
    labelIdentifier: {
        defaultMessage: "Unique identifier",
    },
    labelName: {
        defaultMessage: "Name",
    },
    genericError: {
        defaultMessage: "Failed to get company settings, the team has been notified.",
        id: "genericError",
    },
    settingsUpdateFailure: {
        defaultMessage: "Failed to update company settings, the team has been notified.",
        id: "settingsUpdateFailure",
    },
    settingsUpdated: {
        defaultMessage: "Settings updated",
        id: "settingsUpdated",
    },
    title: {
        defaultMessage: "General settings",
    },
    subTitle: {
        defaultMessage: "Company information",
    },
    enforceSSOTitle: {
        defaultMessage: "Enforce Social Sign-in",
    },
    enforceSSODescription: {
        defaultMessage: "Only allow your users to sign in with an external identity provider such as Microsoft.",
    },
    noPermissions: {
        defaultMessage:
            "You are not authorized to update company settings. Please ensure that you have the 'customers.write' permission which is by default included in the Admin role.",
    },
    saveButton: {
        defaultMessage: "Save",
    },
});

export default messages;
