import { graphql } from "@/gql";

export const getInvitesQuery = graphql(`
    query getInvites($input: GetInvitesInput!) {
        getInvites(input: $input) {
            result {
                id
                email
                roles
                expiredAt
                invitedBy
                createdAt
                invitedByDisplayName
                status
                lastSentAt
            }
        }
    }
`);

export const inviteUserMutation = graphql(`
    mutation inviteUser($input: InviteUserInput!) {
        inviteUser(input: $input) {
            invite {
                id
                email
                roles
                expiredAt
                invitedBy
                createdAt
                invitedByDisplayName
                status
            }
        }
    }
`);

export const resendInvitesMutation = graphql(`
    mutation resendInvites($input: ResendInvitesInput!) {
        resendInvites(input: $input) {
            ids
        }
    }
`);

export const deleteInvitesMutation = graphql(`
    mutation deleteInvite($input: DeleteInvitesInput!) {
        deleteInvites(input: $input) {
            ids
        }
    }
`);

export const editRolesMutation = graphql(`
    mutation editRoles($input: EditRolesInput!) {
        editRoles(input: $input) {
            result {
                id
                roles
            }
        }
    }
`);
