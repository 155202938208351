import { defineMessages } from "react-intl";

const messages = defineMessages({
    menuHeader: {
        defaultMessage: "Settings",
    },
    supportAccess: {
        defaultMessage: "Support access",
    },
    general: {
        defaultMessage: "General",
    },
    users: {
        defaultMessage: "Users & Roles",
    },
    chartColors: {
        defaultMessage: "Chart colors",
    },
    apiTokens: {
        defaultMessage: "API Tokens",
    },

    noPermissions: {
        defaultMessage:
            "You are not authorized to access this page. Please ensure that you have the 'users.write' permission which is by default included in the Admin role.",
    },
});

export default messages;
