import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Route, Routes } from "react-router-dom";

import { Layout } from "@/components/Layout";
import { useBasePath } from "@/contexts/BasePathContext";
import { ApiTokensPage } from "@/pages/ApiTokens";
import { ColorsPage } from "@/pages/Colors";
import { GeneralPage } from "@/pages/General";
import { SupportAccessPage } from "@/pages/SupportAccess";
import { UsersPage } from "@/pages/Users";

import messages from "./messages";

export const AppRouter: React.FC = () => {
    const path = useBasePath();
    const { formatMessage } = useIntl();

    useEffect(() => {
        const data = { source: "ignite-breadcrumbs", payload: [{ text: formatMessage(messages.breadcrumbHeader) }] };
        window.postMessage(data, window.location.origin);
    }, [formatMessage]);

    return (
        <Layout>
            <Routes>
                <Route path={`${path}general/`} element={<GeneralPage />} />
                <Route path={`${path}users/`} element={<UsersPage />} />
                <Route path={`${path}colors/`} element={<ColorsPage />} />
                <Route path={`${path}api-tokens/`} element={<ApiTokensPage />} />
                <Route path={`${path}support-access/`} element={<SupportAccessPage />} />
            </Routes>
        </Layout>
    );
};
