import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Lock } from "@ignite-analytics/icons";
import {
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";

import { useBasePath } from "@/contexts/BasePathContext";
import { usePermission } from "@/lib/tools";

import messages from "./messages";
import { PageLayout } from "./PageLayout";

interface Props {
    children?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
    const location = useLocation();
    const path = useBasePath();
    const { formatMessage } = useIntl();

    const showSupportAccess = useFeatureToggle("show-support-access") || process.env.NODE_ENV === "development";

    const canEditUsers = usePermission("users", { object: "general", relation: "write" });
    const canViewUsers = usePermission("users", { object: "general", relation: "read" });

    const items = [
        { text: formatMessage(messages.general), href: `${path}general/` },
        { text: formatMessage(messages.users), href: `${path}users/`, disabled: !canViewUsers },
        { text: formatMessage(messages.chartColors), href: `${path}colors/` },
        {
            text: formatMessage(messages.apiTokens),
            href: `${path}api-tokens/`,
            disabled: !canEditUsers,
        },
        ...(showSupportAccess ? [{ text: formatMessage(messages.supportAccess), href: `${path}support-access/` }] : []),
    ];

    return (
        <Stack direction="row">
            <Drawer
                variant="permanent"
                sx={{
                    width: "300px",
                }}
                anchor="left"
                PaperProps={{
                    sx: {
                        top: "var(--appbar-height)",
                        left: "unset",
                        background: "#F7F7F8",
                        width: "300px",
                    },
                }}
            >
                <Stack direction="row" alignItems="stretch">
                    <Stack sx={{ minHeight: "100%" }} height="100%">
                        <Stack direction="row" justifyContent="stretch" p={2} pb={1}>
                            <Typography variant="textMd" fontWeight={500}>
                                {formatMessage(messages.menuHeader)}
                            </Typography>
                        </Stack>
                        <List>
                            {items.map(({ text, href, disabled }) => {
                                const isActive = location.pathname.includes(href);
                                return (
                                    <ListItem key={text} disablePadding>
                                        <Link
                                            to={href}
                                            component={NavLink}
                                            style={{ pointerEvents: disabled ? "none" : "auto", flexGrow: 1 }}
                                        >
                                            <ListItemButton disabled={!!disabled}>
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            color={isActive ? "text.primary" : "text.secondary"}
                                                            variant="subtitle2"
                                                        >
                                                            {text}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </Link>
                                        {disabled && (
                                            <ListItemIcon>
                                                <Tooltip
                                                    title={formatMessage(messages.noPermissions)}
                                                    sx={{ zIndex: 100000, pointerEvents: "visible" }}
                                                >
                                                    <Lock fontSize="small" />
                                                </Tooltip>
                                            </ListItemIcon>
                                        )}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Stack>
                </Stack>
            </Drawer>
            <PageLayout>{children}</PageLayout>
        </Stack>
    );
};
