import { defineMessages } from "react-intl";

const messages = defineMessages({
    imageConstraint: {
        defaultMessage: "Image must be either .jpg or .png and be less than 1024x1024 pixels",
    },
    tooltip: {
        defaultMessage: "Update company logo",
    },
});

export default messages;
