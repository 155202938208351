import { graphql } from "@/gql";

export const getCurrentCustomerQuery = graphql(`
    query getCurrentCustomer {
        getCurrentCustomer {
            identifier
            name
            requireSSOLogin
            logo
            currency
        }
    }
`);

export const updateCustomerMutation = graphql(`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input: $input) {
            result {
                name
                requireSSOLogin
            }
        }
    }
`);

export const deleteCustomerMutation = graphql(`
    mutation DeleteCustomer {
        deleteCustomer {
            deleted
        }
    }
`);
