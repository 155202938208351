import { defineMessages } from "react-intl";

const messages = defineMessages({
    confirmationText: {
        defaultMessage: "Please write {confirmationText} to confirm.",
        id: "confirmationText",
    },
    deleteButtonText: {
        defaultMessage: "Delete",
    },
    cancelButtonText: {
        defaultMessage: "Cancel",
    },
});

export default messages;
