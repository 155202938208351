import { Stack, Chip, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { RoleKey, LABELED_ROLES } from "@/interfaces/roles";

type Props = {
    roles: RoleKey[];
    color?: "primary" | "neutral";
    max: number;
};

function getLabel(role: RoleKey) {
    return LABELED_ROLES.find((r) => r.id === role)?.label ?? <FormattedMessage defaultMessage="Unknown role" />;
}
function getDesc(role: RoleKey) {
    return LABELED_ROLES.find((r) => r.id === role)?.description ?? <FormattedMessage defaultMessage="Unknown role" />;
}

type RoleChipProps = {
    role: RoleKey;
    color?: "primary" | "neutral";
    onDelete?: () => void;
};
export const RoleChip = ({ role, color = "primary", onDelete }: RoleChipProps) => {
    const onMousedown: React.MouseEventHandler | undefined =
        onDelete !== undefined ? (e) => e.stopPropagation() : undefined;

    return (
        <Tooltip title={getDesc(role)}>
            <Chip onMouseDown={onMousedown} onDelete={onDelete} size="small" color={color} label={getLabel(role)} />
        </Tooltip>
    );
};

export const RolesChip = ({ roles, max, color = "primary" }: Props) => {
    if (!roles || roles.length === 0) {
        return null;
    }
    // split into the ones we show and the rest
    const rolesToShow = roles.slice(0, max);
    const otherRoles = roles.slice(max);

    return (
        <Stack direction="row" spacing={1} maxWidth="100%">
            {rolesToShow.map((role) => (
                <Tooltip key={role} title={getDesc(role)} placement="top">
                    <Chip
                        size="small"
                        color={color}
                        label={getLabel(role)}
                        sx={{ overflow: "hidden", textOverflow: "hidden", maxWidth: "354px" }}
                    />
                </Tooltip>
            ))}

            {otherRoles.length > 0 && (
                <Tooltip
                    title={
                        <Stack direction="column" spacing={1} p={1}>
                            {otherRoles.map((r) => (
                                <Tooltip key={r} title={getDesc(r)}>
                                    <Chip key={r} size="small" color="primary" label={getLabel(r)} />
                                </Tooltip>
                            ))}
                        </Stack>
                    }
                >
                    <Chip size="small" color="primary" label={`+${otherRoles.length}`} />
                </Tooltip>
            )}
        </Stack>
    );
};
