import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { RolesSelect } from "@/components/RolesSelect";
import { UserInvite } from "@/interfaces/invite";
import { RoleKey } from "@/interfaces/roles";

import messages from "./messages";

interface Props {
    invite: UserInvite | null;
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onSubmit: (data: { id: string; roles: string[] }) => void;
}

export function EditInviteDialog({ invite, open, loading, onClose, onSubmit }: Props) {
    const { formatMessage } = useIntl();
    const [roles, setRoles] = useState<RoleKey[]>([]);
    useEffect(() => {
        if (invite) {
            setRoles(invite.roles);
        }
    }, [invite]);

    const btnDisabled = useMemo(() => roles.length === 0, [roles]);

    function resetState() {
        setRoles(invite?.roles || []);
    }
    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!invite) {
            return;
        }
        const data = {
            id: invite.id,
            roles,
        };
        onSubmit(data);
    }

    if (!invite) {
        return null;
    }

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={() => {
                resetState();
                onClose();
            }}
        >
            <Stack component="form" onSubmit={handleSubmit}>
                <DialogTitle>{formatMessage(messages.title)}</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="body2">{formatMessage(messages.description)}</DialogContentText>
                    <RolesSelect value={roles} onChange={setRoles} />
                </DialogContent>
                <DialogActions sx={{ pb: 3, px: 3 }}>
                    <Button type="button" variant="text" color="inherit" onClick={onClose}>
                        {formatMessage(messages.cancelButtonText)}
                    </Button>
                    <LoadingButton disabled={btnDisabled} variant="contained" loading={loading} type="submit">
                        {formatMessage(messages.submitButtonText)}
                    </LoadingButton>
                </DialogActions>
            </Stack>
        </Dialog>
    );
}
