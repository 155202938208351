import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Container, CircularProgress, Button, Alert } from "@mui/material";
import { useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useIntl } from "react-intl";

import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/tools";

import { ColorsRow } from "./ColorRow";
import { DEFAULT_COLOR } from "./constants";
import { useColors } from "./hooks";
import messages from "./messages";

export function ColorsPage() {
    const { colors, loading, save, move, insert, remove, updateColorCode, hasChanges } = useColors();
    const { formatMessage } = useIntl();
    const hasPermissions = usePermission("customers", { object: "general", relation: "write" }) || 1 == 1;
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updateFailed, setUpdateFailed] = useState(false);

    function onSave() {
        setSaving(true);
        save()
            .then((success) => {
                if (success) {
                    setSaved(true);
                    setTimeout(() => setSaved(false), 3000);
                } else {
                    setUpdateFailed(true);
                }
            })
            .catch(() => {
                setSaving(false);
                setUpdateFailed(true);
            })
            .finally(() => {
                setSaving(false);
            });
    }
    const saveButtonDisabled = useMemo(() => {
        if (!hasPermissions) return true;
        if (!hasChanges) {
            if (saved) {
                return false;
            }
            return true;
        }
        return false;
    }, [hasPermissions, hasChanges, saved]);

    // Update breadcrumbs in base app
    useSetBreadcrumbs(formatMessage(messages.title));

    return (
        <Container disableGutters={false} sx={{ mx: 0 }} maxWidth="lg">
            <DndProvider backend={HTML5Backend}>
                <Stack py={2}>
                    <Typography variant="h6">{formatMessage(messages.subTitle)}</Typography>
                    <Typography variant="body2">{formatMessage(messages.description)}</Typography>
                    {hasPermissions === false && (
                        <Alert severity="warning" sx={{ my: 2 }}>
                            {formatMessage({ defaultMessage: "You don't have permissions to edit colors." })}
                        </Alert>
                    )}
                    {updateFailed && (
                        <Alert severity="error" sx={{ my: 2 }} onClose={() => setUpdateFailed(false)}>
                            {formatMessage({ defaultMessage: "Failed to update colors. Please try again later." })}
                        </Alert>
                    )}

                    {!loading && (
                        <Container data-testid="colors-container" sx={{ display: "inline-block", my: 2 }}>
                            {colors.map((color, index) => (
                                <ColorsRow
                                    disabled={!hasPermissions}
                                    onMove={move}
                                    onDelete={() => remove(index)}
                                    onColorChange={(hex) => updateColorCode(index, hex)}
                                    index={index}
                                    key={color.id}
                                    color={color}
                                />
                            ))}
                        </Container>
                    )}
                    {!loading && colors.length === 0 && (
                        <Stack p={3} alignItems="center" spacing={2}>
                            <Typography color="text.secondary">{formatMessage(messages.noColors)}</Typography>
                        </Stack>
                    )}
                    {loading && (
                        <Stack direction="row" justifyContent="center" p={5}>
                            <CircularProgress />
                        </Stack>
                    )}
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button
                            disabled={hasPermissions === false}
                            color="secondary"
                            variant="outlined"
                            onClick={() => insert(DEFAULT_COLOR)}
                        >
                            {formatMessage(messages.addColor)}
                        </Button>
                        <LoadingButton
                            disabled={saveButtonDisabled}
                            color={saved ? "success" : "primary"}
                            variant="contained"
                            loading={saving}
                            onClick={onSave}
                        >
                            {saved ? formatMessage(messages.savedButton) : formatMessage(messages.saveButton)}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DndProvider>
        </Container>
    );
}
