import { defineMessages } from "react-intl";

const messages = defineMessages({
    confirmButtonText: {
        defaultMessage: "Proceed",
    },
    cancelButtonText: {
        defaultMessage: "Cancel",
    },
});

export default messages;
