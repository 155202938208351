import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Edit invite",
    },
    description: {
        defaultMessage: "Change roles for this invite",
    },
    submitButtonText: {
        defaultMessage: "Submit",
    },
    cancelButtonText: {
        defaultMessage: "Cancel",
    },
});

export default messages;
