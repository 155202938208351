import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

// throws if it fails for some reason
export async function identityDataFromOry(): Promise<{ id: string; email: string; tenant: string }> {
    const res = await fetch(`${import.meta.env.VITE_ORY_API_URL}/sessions/whoami`, { credentials: "include" });
    const j = await res.json();
    return { id: j.identity.id, email: j.identity.traits.email, tenant: j.identity.metadata_public.tenant };
}

export function useCurrentUser() {
    const [userId, setUserId] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [tenant, setTenant] = useState<string | null>(null);
    useEffect(() => {
        identityDataFromOry()
            .then(({ id, email, tenant }) => {
                setUserId(id);
                setEmail(email);
                setTenant(tenant);
            })
            .catch((e) => {
                // unable to get user info, we'll capture to sentry, but it's not really critical anyways.
                Sentry.captureException(e, { tags: { app: "company-settings" } });
            });
    }, []);
    return { id: userId, email, tenant };
}
