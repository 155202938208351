import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Color settings",
    },
    subTitle: {
        defaultMessage: "Select chart colors",
    },
    description: {
        defaultMessage:
            "These are the colors that will be used for all charts in the ignite platform. You can customize these to match your companys branding profile.",
    },
    saveButton: {
        defaultMessage: "Save",
    },
    savedButton: {
        defaultMessage: "Saved",
    },

    couldNotDelete: {
        defaultMessage: "Could not delete all colors, please check your internet connection and try again",
    },
    noColors: {
        defaultMessage: "There are no colors defined yet.",
    },
    addColor: {
        defaultMessage: "Add new color",
    },
});

export default messages;
