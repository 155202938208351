import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        defaultMessage: "Copy your token",
    },
    description: {
        defaultMessage:
            "Please copy your newly created token and store it in a safe place. You will not be able to copy it later on.",
    },
    closeButton: {
        defaultMessage: "Close",
    },
    copyText: {
        defaultMessage: "Token copied!",
    },
});

export default messages;
