import { Plus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Alert, Avatar, CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { FormEvent, useEffect } from "react";
import { useIntl } from "react-intl";

import messages from "./messages";

interface Props {
    customer: { logo?: string };
}

export function CustomerLogo({ customer }: Props) {
    const { formatMessage } = useIntl();
    const [url, setUrl] = React.useState<string | undefined>(undefined);
    const [alert, setAlert] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
        if (customer.logo) {
            setUrl(customer.logo);
        }
    }, [customer]);

    async function previewImage(e: FormEvent<HTMLInputElement>) {
        track("update customer logo");
        const target = e.target as HTMLInputElement;
        const files = target.files;
        if (!files) return;
        // do client-side validation , check that it is less than 1024 x 1024
        const img = new Image();
        img.src = URL.createObjectURL(files[0]);
        img.onload = function () {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            if (width > 1024 || height > 1024) {
                setAlert(formatMessage(messages.imageConstraint));
                return;
            }
        };
        const formData = new FormData();
        formData.append("logo", files[0]);
        const res = await fetch(`${import.meta.env.VITE_CUSTOMERS_API_URL}/logos`, {
            method: "POST",
            credentials: "include",
            body: formData,
        });
        if (res.status == 400) {
            setAlert(formatMessage(messages.imageConstraint));
            return;
        }
        if (res.status !== 200) {
            Sentry.captureMessage("failed to upload logo", { tags: { status: res.status, app: "company-settings" } });
        }
        const { url } = (await res.json()) as { url: string };
        setUrl(url);
    }

    return (
        <Stack alignItems="start" gap={2} justifyItems="stretch">
            {alert != "" && (
                <Alert severity="info" sx={{ mb: 2 }} onClose={() => setAlert("")}>
                    {alert}
                </Alert>
            )}
            <Tooltip title={formatMessage(messages.tooltip)}>
                <IconButton aria-label="upload picture" component="label">
                    <input
                        data-testid="customer-logo-upload"
                        hidden
                        accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                        onChange={async (event) => {
                            setLoading(true);
                            try {
                                await previewImage(event);
                            } catch (e) {
                                Sentry.captureException(e, { tags: { app: "company-settings" } });
                            }
                            setLoading(false);
                        }}
                        type="file"
                    />
                    <Avatar
                        alt="Customer Logo"
                        src={url}
                        sx={(theme) => ({
                            width: 80,
                            height: 80,
                            bgcolor: "white",
                            color: "text.primary",
                            boxShadow: theme.shadows[4],
                        })}
                    >
                        {loading ? <CircularProgress data-testid="customer-logo-loading" /> : <Plus />}
                    </Avatar>
                </IconButton>
            </Tooltip>
        </Stack>
    );
}
