export const Illustration = () => {
    return (
        <svg width="388" height="200" viewBox="0 0 388 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M377.514 82.7288H106.291C105.139 82.7288 104.035 83.1863 103.221 84.0006C102.406 84.8149 101.949 85.9194 101.949 87.071C101.949 88.2226 102.406 89.3271 103.221 90.1414C104.035 90.9558 105.139 91.4133 106.291 91.4133H113.974V195.293C113.974 196.445 114.431 197.549 115.245 198.364C116.06 199.178 117.164 199.635 118.316 199.635C119.467 199.635 120.572 199.178 121.386 198.364C122.201 197.549 122.658 196.445 122.658 195.293L122.724 91.4133H340.408L360.479 195.293C360.479 196.445 360.937 197.549 361.751 198.364C362.566 199.178 363.67 199.635 364.822 199.635C365.973 199.635 367.078 199.178 367.892 198.364C368.706 197.549 369.164 196.445 369.164 195.293L370.427 91.4133H377.514C378.666 91.4133 379.771 90.9558 380.585 90.1414C381.399 89.3271 381.857 88.2226 381.857 87.071C381.857 85.9194 381.399 84.8149 380.585 84.0006C379.771 83.1863 378.666 82.7288 377.514 82.7288Z"
                fill="#CCCCCC"
            />
            <path
                d="M153.304 63.3803V81.7685H107.235C106.027 81.8105 104.823 81.6143 103.692 81.1911C102.56 80.7678 101.523 80.1259 100.64 79.3018C99.7563 78.4778 99.0438 77.4878 98.543 76.3884C98.0421 75.289 97.7627 74.1017 97.7207 72.8943C97.6787 71.6869 97.8749 70.4831 98.2982 69.3515C98.7214 68.2199 99.3634 67.1828 100.187 66.2994C101.011 65.4159 102.001 64.7034 103.101 64.2026C104.2 63.7017 105.388 63.4223 106.595 63.3803C106.808 63.3729 107.021 63.3729 107.235 63.3803L153.304 63.3803Z"
                fill="#F2F2F2"
            />
            <path
                d="M154.436 81.6271C154.436 81.8146 154.361 81.9945 154.228 82.1271C154.096 82.2597 153.916 82.3342 153.728 82.3343H106.414C103.846 82.3325 101.383 81.3109 99.5672 79.494C97.7516 77.6771 96.7317 75.2137 96.7317 72.6451C96.7317 70.0766 97.7516 67.6131 99.5672 65.7962C101.383 63.9794 103.846 62.9578 106.414 62.956H153.728C153.821 62.9557 153.914 62.9738 154 63.0093C154.086 63.0447 154.164 63.0968 154.23 63.1625C154.296 63.2282 154.348 63.3062 154.383 63.3921C154.419 63.4781 154.437 63.5702 154.437 63.6632C154.437 63.7562 154.419 63.8484 154.383 63.9343C154.348 64.0202 154.296 64.0983 154.23 64.164C154.164 64.2297 154.086 64.2817 154 64.3171C153.914 64.3526 153.821 64.3707 153.728 64.3704H106.414C104.222 64.3738 102.12 65.247 100.571 66.7985C99.022 68.3499 98.1519 70.4527 98.1519 72.6451C98.1519 74.8376 99.022 76.9404 100.571 78.4918C102.12 80.0433 104.222 80.9165 106.414 80.9198H153.728C153.916 80.9199 154.096 80.9945 154.228 81.1271C154.361 81.2597 154.436 81.4395 154.436 81.6271Z"
                fill="#FE5C45"
            />
            <path
                d="M153.304 67.6238H103.797C103.76 67.624 103.724 67.6093 103.697 67.5831C103.671 67.5568 103.655 67.521 103.655 67.4836C103.655 67.4462 103.669 67.4102 103.695 67.3834C103.722 67.3567 103.757 67.3414 103.795 67.3409H153.304C153.341 67.3413 153.377 67.3563 153.403 67.3828C153.429 67.4093 153.444 67.445 153.444 67.4823C153.444 67.5196 153.429 67.5554 153.403 67.5818C153.377 67.6083 153.341 67.6234 153.304 67.6238Z"
                fill="#CCCCCC"
            />
            <path
                d="M153.304 71.0185H103.797C103.76 71.0187 103.724 71.0041 103.697 70.9778C103.671 70.9515 103.655 70.9158 103.655 70.8784C103.655 70.841 103.669 70.8049 103.695 70.7782C103.722 70.7514 103.757 70.7361 103.795 70.7356H153.304C153.341 70.736 153.377 70.7511 153.403 70.7775C153.429 70.804 153.444 70.8398 153.444 70.8771C153.444 70.9143 153.429 70.9501 153.403 70.9766C153.377 71.0031 153.341 71.0181 153.304 71.0185Z"
                fill="#CCCCCC"
            />
            <path
                d="M153.304 74.4133H103.797C103.76 74.4135 103.724 74.3988 103.697 74.3726C103.671 74.3463 103.655 74.3105 103.655 74.2731C103.655 74.2357 103.669 74.1997 103.695 74.1729C103.722 74.1462 103.757 74.1309 103.795 74.1304H153.304C153.341 74.1307 153.377 74.1458 153.403 74.1723C153.429 74.1988 153.444 74.2345 153.444 74.2718C153.444 74.3091 153.429 74.3448 153.403 74.3713C153.377 74.3978 153.341 74.4129 153.304 74.4133Z"
                fill="#CCCCCC"
            />
            <path
                d="M153.304 77.808H103.797C103.76 77.8082 103.724 77.7935 103.697 77.7673C103.671 77.741 103.655 77.7052 103.655 77.6678C103.655 77.6304 103.669 77.5944 103.695 77.5676C103.722 77.5409 103.757 77.5256 103.795 77.5251H153.304C153.341 77.5255 153.377 77.5405 153.403 77.567C153.429 77.5935 153.444 77.6293 153.444 77.6665C153.444 77.7038 153.429 77.7396 153.403 77.766C153.377 77.7925 153.341 77.8076 153.304 77.808Z"
                fill="#CCCCCC"
            />
            <path
                d="M144.62 44.0072V62.3955H98.5502C96.1117 62.4803 93.7395 61.593 91.9553 59.9287C90.1711 58.2645 89.121 55.9596 89.0362 53.5212C88.9514 51.0828 89.8387 48.7105 91.5029 46.9263C93.1672 45.1421 95.472 44.0921 97.9105 44.0072C98.1236 43.9998 98.3369 43.9998 98.5502 44.0072H144.62Z"
                fill="#F2F2F2"
            />
            <path
                d="M145.751 62.254C145.751 62.4416 145.677 62.6214 145.544 62.754C145.411 62.8866 145.231 62.9612 145.044 62.9613H97.7296C95.1599 62.9613 92.6954 61.9404 90.8784 60.1234C89.0613 58.3063 88.0405 55.8418 88.0405 53.2721C88.0405 50.7024 89.0613 48.2379 90.8784 46.4208C92.6954 44.6037 95.1599 43.5829 97.7296 43.5829H145.044C145.137 43.5829 145.229 43.6012 145.315 43.6367C145.4 43.6723 145.478 43.7244 145.544 43.7901C145.61 43.8557 145.662 43.9337 145.697 44.0195C145.733 44.1053 145.751 44.1973 145.751 44.2902C145.751 44.383 145.733 44.475 145.697 44.5608C145.662 44.6466 145.61 44.7246 145.544 44.7902C145.478 44.8559 145.4 44.908 145.315 44.9435C145.229 44.9791 145.137 44.9974 145.044 44.9974H97.7296C95.5351 44.9974 93.4304 45.8692 91.8785 47.421C90.3267 48.9728 89.4549 51.0775 89.4549 53.2721C89.4549 55.4667 90.3267 57.5714 91.8785 59.1232C93.4304 60.675 95.5351 61.5468 97.7296 61.5468H145.044C145.231 61.5469 145.411 61.6214 145.544 61.754C145.677 61.8866 145.751 62.0665 145.751 62.254Z"
                fill="#3F3D56"
            />
            <path
                d="M144.62 48.2507H95.1128C95.0754 48.2509 95.0394 48.2362 95.0128 48.21C94.9861 48.1837 94.9709 48.1479 94.9706 48.1105C94.9702 48.0731 94.9847 48.0371 95.0109 48.0103C95.0371 47.9836 95.0728 47.9683 95.1102 47.9678H144.62C144.657 47.9681 144.692 47.9832 144.719 48.0097C144.745 48.0362 144.76 48.0719 144.76 48.1092C144.76 48.1465 144.745 48.1822 144.719 48.2087C144.692 48.2352 144.657 48.2503 144.62 48.2507Z"
                fill="#CCCCCC"
            />
            <path
                d="M144.62 51.6454H95.1128C95.0754 51.6456 95.0394 51.631 95.0128 51.6047C94.9861 51.5785 94.9709 51.5427 94.9706 51.5053C94.9702 51.4679 94.9847 51.4318 95.0109 51.4051C95.0371 51.3783 95.0728 51.363 95.1102 51.3625H144.62C144.657 51.3629 144.692 51.378 144.719 51.4045C144.745 51.4309 144.76 51.4667 144.76 51.504C144.76 51.5412 144.745 51.577 144.719 51.6035C144.692 51.63 144.657 51.645 144.62 51.6454Z"
                fill="#CCCCCC"
            />
            <path
                d="M144.62 55.0401H95.1128C95.0754 55.0403 95.0394 55.0257 95.0128 54.9994C94.9861 54.9732 94.9709 54.9374 94.9706 54.9C94.9702 54.8626 94.9847 54.8266 95.0109 54.7998C95.0371 54.773 95.0728 54.7578 95.1102 54.7573H144.62C144.657 54.7576 144.692 54.7727 144.719 54.7992C144.745 54.8257 144.76 54.8614 144.76 54.8987C144.76 54.936 144.745 54.9717 144.719 54.9982C144.692 55.0247 144.657 55.0398 144.62 55.0401Z"
                fill="#CCCCCC"
            />
            <path
                d="M144.62 58.4349H95.1128C95.0754 58.4351 95.0394 58.4205 95.0128 58.3942C94.9861 58.3679 94.9709 58.3322 94.9706 58.2948C94.9702 58.2573 94.9847 58.2213 95.0109 58.1945C95.0371 58.1678 95.0728 58.1525 95.1102 58.152H144.62C144.657 58.1524 144.692 58.1674 144.719 58.1939C144.745 58.2204 144.76 58.2562 144.76 58.2935C144.76 58.3307 144.745 58.3665 144.719 58.393C144.692 58.4195 144.657 58.4345 144.62 58.4349Z"
                fill="#CCCCCC"
            />
            <path
                d="M198.212 147.797V131.764C198.212 131.055 197.931 130.376 197.43 129.874C196.928 129.373 196.249 129.092 195.54 129.092H134.749C134.04 129.092 133.36 129.373 132.859 129.874C132.358 130.376 132.077 131.055 132.077 131.764V147.797H198.212Z"
                fill="#CCCCCC"
            />
            <path d="M198.212 153.809H132.077V174.518H198.212V153.809Z" fill="#CCCCCC" />
            <path
                d="M132.077 180.531V196.564C132.077 197.272 132.358 197.952 132.859 198.453C133.36 198.954 134.04 199.236 134.749 199.236H195.54C196.249 199.236 196.928 198.954 197.43 198.453C197.931 197.952 198.212 197.272 198.212 196.564V180.531H132.077Z"
                fill="#CCCCCC"
            />
            <path
                d="M164.81 141.116C165.917 141.116 166.814 140.219 166.814 139.112C166.814 138.005 165.917 137.108 164.81 137.108C163.704 137.108 162.806 138.005 162.806 139.112C162.806 140.219 163.704 141.116 164.81 141.116Z"
                fill="white"
            />
            <path
                d="M164.81 167.838C165.917 167.838 166.814 166.941 166.814 165.834C166.814 164.727 165.917 163.83 164.81 163.83C163.704 163.83 162.806 164.727 162.806 165.834C162.806 166.941 163.704 167.838 164.81 167.838Z"
                fill="white"
            />
            <path
                d="M164.918 193.994C166.025 193.994 166.922 193.096 166.922 191.989C166.922 190.883 166.025 189.985 164.918 189.985C163.811 189.985 162.914 190.883 162.914 191.989C162.914 193.096 163.811 193.994 164.918 193.994Z"
                fill="white"
            />
            <path
                d="M190.714 110.811V129.199H144.645C142.207 129.282 139.837 128.394 138.054 126.73C136.271 125.066 135.222 122.762 135.137 120.325C135.052 117.888 135.939 115.516 137.601 113.732C139.264 111.948 141.567 110.898 144.004 110.811C144.218 110.804 144.431 110.804 144.645 110.811H190.714Z"
                fill="#F2F2F2"
            />
            <path
                d="M191.846 129.058C191.846 129.245 191.771 129.425 191.639 129.558C191.506 129.69 191.326 129.765 191.139 129.765H143.824C141.255 129.766 138.791 128.746 136.973 126.93C135.156 125.114 134.134 122.651 134.133 120.082C134.131 117.512 135.15 115.048 136.965 113.23C138.78 111.412 141.243 110.389 143.813 110.387H191.139C191.232 110.386 191.324 110.404 191.41 110.44C191.496 110.475 191.574 110.527 191.64 110.593C191.706 110.659 191.758 110.737 191.794 110.823C191.829 110.909 191.848 111.001 191.848 111.094C191.848 111.187 191.829 111.279 191.794 111.365C191.758 111.451 191.706 111.529 191.64 111.595C191.574 111.66 191.496 111.712 191.41 111.748C191.324 111.783 191.232 111.801 191.139 111.801H143.824C141.632 111.804 139.53 112.677 137.98 114.229C136.431 115.78 135.56 117.883 135.56 120.076C135.56 122.269 136.431 124.372 137.98 125.923C139.53 127.475 141.632 128.348 143.824 128.35H191.139C191.326 128.351 191.506 128.425 191.639 128.558C191.771 128.69 191.846 128.87 191.846 129.058Z"
                fill="#3F3D56"
            />
            <path
                d="M190.714 115.054H141.207C141.17 115.055 141.134 115.04 141.107 115.014C141.081 114.987 141.066 114.952 141.065 114.914C141.065 114.877 141.079 114.841 141.106 114.814C141.132 114.787 141.167 114.772 141.205 114.771H190.714C190.751 114.772 190.787 114.787 190.813 114.813C190.84 114.84 190.854 114.876 190.854 114.913C190.854 114.95 190.84 114.986 190.813 115.012C190.787 115.039 190.751 115.054 190.714 115.054Z"
                fill="#CCCCCC"
            />
            <path
                d="M190.714 118.449H141.207C141.17 118.449 141.134 118.435 141.107 118.408C141.081 118.382 141.066 118.346 141.065 118.309C141.065 118.272 141.079 118.236 141.106 118.209C141.132 118.182 141.167 118.167 141.205 118.166H190.714C190.751 118.167 190.787 118.182 190.813 118.208C190.84 118.235 190.854 118.27 190.854 118.308C190.854 118.345 190.84 118.381 190.813 118.407C190.787 118.434 190.751 118.449 190.714 118.449Z"
                fill="#CCCCCC"
            />
            <path
                d="M190.714 121.844H141.207C141.17 121.844 141.134 121.83 141.107 121.803C141.081 121.777 141.066 121.741 141.065 121.704C141.065 121.666 141.079 121.63 141.106 121.604C141.132 121.577 141.167 121.562 141.205 121.561H190.714C190.751 121.561 190.787 121.576 190.813 121.603C190.84 121.629 190.854 121.665 190.854 121.702C190.854 121.74 190.84 121.776 190.813 121.802C190.787 121.828 190.751 121.844 190.714 121.844Z"
                fill="#CCCCCC"
            />
            <path
                d="M190.714 125.239H141.207C141.17 125.239 141.134 125.224 141.107 125.198C141.081 125.172 141.066 125.136 141.065 125.098C141.065 125.061 141.079 125.025 141.106 124.998C141.132 124.972 141.167 124.956 141.205 124.956H190.714C190.751 124.956 190.787 124.971 190.813 124.998C190.84 125.024 190.854 125.06 190.854 125.097C190.854 125.134 190.84 125.17 190.813 125.197C190.787 125.223 190.751 125.238 190.714 125.239Z"
                fill="#CCCCCC"
            />
            <path
                d="M136.608 37.873H115.618C115.419 37.8728 115.223 37.9185 115.044 38.0066L109.746 40.6587C109.572 40.7328 109.423 40.8566 109.319 41.0145C109.214 41.1725 109.159 41.3578 109.159 41.5472C109.159 41.7366 109.214 41.9218 109.319 42.0798C109.423 42.2378 109.572 42.3616 109.746 42.4357L115.044 45.0878C115.223 45.1758 115.419 45.2215 115.618 45.2214H136.608C136.897 45.2381 137.182 45.1402 137.399 44.9489C137.617 44.7576 137.75 44.4883 137.771 44.1993V38.8951C137.75 38.6061 137.617 38.3367 137.399 38.1454C137.182 37.9541 136.897 37.8563 136.608 37.873Z"
                fill="#3F3D56"
            />
            <path
                d="M137.771 38.8951V44.1993C137.75 44.4883 137.617 44.7576 137.399 44.949C137.182 45.1403 136.897 45.2382 136.608 45.2214H132.426V37.873H136.608C136.897 37.8563 137.182 37.9542 137.399 38.1455C137.617 38.3368 137.75 38.6061 137.771 38.8951Z"
                fill="#FE5C45"
            />
            <path
                d="M10.7684 141.807C13.4711 142.757 16.3729 142.995 19.1943 142.498C22.0157 142.001 24.6616 140.785 26.877 138.969C32.5191 134.233 34.2881 126.432 35.7275 119.207C37.1466 112.085 38.5657 104.962 39.9848 97.8399L31.0716 103.977C24.6618 108.391 18.1076 112.946 13.6695 119.338C9.23142 125.731 7.29416 134.458 10.86 141.375"
                fill="#E6E6E6"
            />
            <path
                d="M11.5302 167.676C10.6281 161.105 9.7005 154.45 10.3333 147.808C10.8953 141.91 12.6948 136.148 16.3584 131.425C18.303 128.922 20.6693 126.778 23.3508 125.088C24.05 124.647 24.6936 125.755 23.9974 126.195C19.3577 129.127 15.769 133.457 13.7488 138.56C11.5175 144.236 11.1592 150.422 11.5437 156.451C11.7763 160.097 12.269 163.718 12.7656 167.336C12.8048 167.499 12.7804 167.672 12.6973 167.818C12.6143 167.964 12.4788 168.073 12.3182 168.124C12.1545 168.168 11.9799 168.146 11.8324 168.062C11.6848 167.978 11.5763 167.84 11.5302 167.676Z"
                fill="#F2F2F2"
            />
            <path
                d="M20.2194 156.048C21.3785 157.81 22.9718 159.244 24.8458 160.211C26.7198 161.178 28.8114 161.646 30.9189 161.571C36.3351 161.314 40.8506 157.533 44.9153 153.945L56.9381 143.329L48.981 142.949C43.2588 142.675 37.3889 142.419 31.9387 144.184C26.4884 145.948 21.4619 150.195 20.4653 155.837"
                fill="#E6E6E6"
            />
            <path
                d="M8.98082 171.454C13.3229 163.771 18.3593 155.232 27.3586 152.503C29.8608 151.746 32.4846 151.476 35.0886 151.705C35.9093 151.775 35.7043 153.04 34.8851 152.97C30.5203 152.607 26.1639 153.76 22.5493 156.233C19.071 158.601 16.3629 161.892 14.0707 165.383C12.6666 167.521 11.409 169.75 10.1511 171.975C9.74911 172.687 8.57413 172.173 8.98082 171.454Z"
                fill="#F2F2F2"
            />
            <path
                d="M2.0235 179.541C2.1698 177.845 2.77843 176.223 3.78313 174.85C4.78782 173.476 6.15005 172.405 7.72145 171.753V167.311H15.1712V171.893C16.6653 172.577 17.9511 173.646 18.8975 174.989C19.844 176.333 20.4173 177.903 20.5589 179.541L22.3106 199.904H0.271729L2.0235 179.541Z"
                fill="#F2F2F2"
            />
            <path
                d="M255.376 82.3113L253.16 81.3782L251.64 70.2684H231.394L229.746 81.3328L227.764 82.3241C227.669 82.3715 227.593 82.4496 227.548 82.5457C227.503 82.6418 227.492 82.7502 227.516 82.8534C227.54 82.9566 227.599 83.0486 227.682 83.1144C227.765 83.1802 227.868 83.2161 227.974 83.2161H255.193C255.302 83.2161 255.408 83.1785 255.492 83.1096C255.576 83.0408 255.634 82.945 255.655 82.8384C255.677 82.7319 255.66 82.6212 255.61 82.5251C255.559 82.429 255.476 82.3535 255.376 82.3113Z"
                fill="#E6E6E6"
            />
            <path
                d="M291.354 71.7465H191.903C191.381 71.7454 190.881 71.5373 190.512 71.1679C190.144 70.7985 189.937 70.298 189.937 69.7762V55.7523H293.319V69.7762C293.319 70.298 293.112 70.7985 292.744 71.1679C292.375 71.5373 291.875 71.7454 291.354 71.7465Z"
                fill="#CCCCCC"
            />
            <path
                d="M293.423 62.3804H189.846V2.37516C189.847 1.74543 190.097 1.14172 190.543 0.696443C190.988 0.251171 191.592 0.000704617 192.221 0H291.048C291.678 0.000704606 292.282 0.251171 292.727 0.696443C293.172 1.14171 293.423 1.74543 293.423 2.37516L293.423 62.3804Z"
                fill="#3F3D56"
            />
            <path
                d="M287.237 58.0252H196.032C195.547 58.0247 195.081 57.8316 194.738 57.4883C194.395 57.145 194.202 56.6796 194.201 56.1941V6.18627C194.202 5.70078 194.395 5.23533 194.738 4.89203C195.081 4.54873 195.547 4.35563 196.032 4.35507H287.237C287.723 4.35563 288.188 4.54873 288.531 4.89203C288.875 5.23533 289.068 5.70078 289.068 6.18627V56.1941C289.068 56.6796 288.875 57.145 288.531 57.4883C288.188 57.8316 287.723 58.0247 287.237 58.0252Z"
                fill="white"
            />
            <path
                d="M263.215 75.6705C263.804 75.1266 264.505 74.7184 265.269 74.4747C266.032 74.231 266.84 74.1577 267.635 74.26C268.43 74.3622 269.193 74.6376 269.87 75.0666C270.547 75.4956 271.122 76.0679 271.554 76.7431L283.891 74.5498L287.052 81.7216L269.587 84.5743C268.405 85.2249 267.025 85.4191 265.708 85.1202C264.392 84.8214 263.232 84.0501 262.446 82.9526C261.661 81.855 261.305 80.5076 261.447 79.1654C261.589 77.8233 262.218 76.5797 263.215 75.6705Z"
                fill="#FFB6B6"
            />
            <path
                d="M274.637 84.8277L287.498 84.6674L289.126 84.6454L315.292 84.3157C315.292 84.3157 322.398 77.5368 328.585 70.5574L327.718 64.2847C326.872 58.1288 323.818 52.4898 319.125 48.4173C310.264 54.3386 305.124 66.0592 305.124 66.0592L288.9 69.9397L287.277 70.3279L277.196 72.7368L274.637 84.8277Z"
                fill="#FE5C45"
            />
            <path
                d="M340.474 123.382H316.467C316.467 124.411 290.225 125.245 290.225 125.245C289.646 125.681 289.118 126.181 288.652 126.736C287.251 128.407 286.485 130.519 286.49 132.699V134.19C286.491 136.661 287.473 139.03 289.22 140.778C290.967 142.525 293.337 143.507 295.807 143.508H340.474C342.945 143.505 345.313 142.523 347.06 140.776C348.807 139.029 349.79 136.661 349.792 134.19V132.699C349.788 130.229 348.805 127.861 347.059 126.115C345.312 124.368 342.944 123.385 340.474 123.382Z"
                fill="#E4E4E4"
            />
            <path d="M323.017 143.137H313.326V199.069H323.017V143.137Z" fill="#E4E4E4" />
            <path
                d="M285.747 198.729C285.747 199.776 300.431 199.134 318.544 199.134C336.658 199.134 351.342 199.776 351.342 198.729C351.342 197.682 336.658 189.379 318.544 189.379C300.431 189.379 285.747 197.682 285.747 198.729Z"
                fill="#E4E4E4"
            />
            <path
                d="M279.238 192.338L272.026 192.338L268.595 164.521L279.239 164.521L279.238 192.338Z"
                fill="#FFB6B6"
            />
            <path
                d="M281.077 199.329L257.823 199.328V199.034C257.823 196.633 258.777 194.331 260.474 192.634C262.171 190.937 264.473 189.983 266.874 189.983H266.874L281.077 189.983L281.077 199.329Z"
                fill="#2F2E41"
            />
            <path d="M257.923 192.338L250.711 192.338L247.28 164.521L257.924 164.521L257.923 192.338Z" fill="#FFB6B6" />
            <path
                d="M259.762 199.329L236.508 199.328V199.034C236.508 196.633 237.462 194.331 239.159 192.634C240.856 190.937 243.158 189.983 245.559 189.983H245.559L259.762 189.983L259.762 199.329Z"
                fill="#2F2E41"
            />
            <path
                d="M322.524 32.1932C329.449 32.1932 335.063 26.5792 335.063 19.6539C335.063 12.7287 329.449 7.11465 322.524 7.11465C315.599 7.11465 309.985 12.7287 309.985 19.6539C309.985 26.5792 315.599 32.1932 322.524 32.1932Z"
                fill="#FFB6B6"
            />
            <path
                d="M342.819 105.568C342.819 105.568 347.026 127.816 331.018 130.649C315.01 133.482 291.902 135.473 291.902 135.473L281.524 183.889L267.583 182.644C267.583 182.644 269.437 124.363 273.997 119.803C278.557 115.243 307.376 101.59 307.376 101.59L342.819 105.568Z"
                fill="#2F2E41"
            />
            <path
                d="M311.554 96.2442L304.552 103.268C304.552 103.268 254 111.411 252.049 123.087C249.105 140.715 245.627 179.048 245.627 179.048L260.826 177.908L266.787 134.333L291.105 128.802L318.773 113.723L311.554 96.2442Z"
                fill="#2F2E41"
            />
            <path
                d="M334.59 12.169C333.353 9.83353 331.78 5.17905 329.281 4.31582C327.894 3.85576 326.46 3.55028 325.005 3.40491L320.759 5.03821L323.07 3.24068C322.62 3.21387 322.17 3.19196 321.721 3.17493L318.856 4.27693L320.322 3.1368C317.65 3.10939 314.893 3.38713 312.823 5.01326C311.059 6.39954 309.311 11.651 309.028 13.877C308.726 16.0065 308.831 18.174 309.34 20.264L310.065 20.954C310.319 20.372 310.51 19.7645 310.634 19.1419C310.895 17.9786 311.394 16.8822 312.102 15.9227C312.809 14.9631 313.708 14.1615 314.742 13.5688L314.782 13.5465C315.999 12.8616 317.476 12.8931 318.871 12.9434L325.499 13.1819C327.094 13.2393 328.811 13.3402 330.055 14.3405C330.845 15.0784 331.466 15.9784 331.876 16.9788C332.494 18.2257 333.701 22.9285 333.701 22.9285C333.701 22.9285 334.384 22.0408 334.711 22.7015C335.236 20.9776 335.555 19.1977 335.662 17.3988C335.766 15.592 335.397 13.7891 334.59 12.169Z"
                fill="#2F2E41"
            />
            <path
                d="M378.447 25.1606C377.647 25.2133 376.869 25.4407 376.166 25.8268C375.464 26.213 374.854 26.7484 374.381 27.3955C373.908 28.0426 373.583 28.7855 373.428 29.572C373.273 30.3585 373.292 31.1694 373.484 31.9476L363.401 39.3856L366.427 46.6157L380.519 35.9118C381.805 35.502 382.896 34.6348 383.585 33.4744C384.274 32.3141 384.514 30.9412 384.258 29.616C384.003 28.2908 383.27 27.1053 382.199 26.2841C381.128 25.463 379.793 25.0632 378.447 25.1606Z"
                fill="#FFB6B6"
            />
            <path
                d="M376.824 40.3455L351.546 68.4198C351.546 68.4198 339.457 64.5499 330.108 60.3544L325.908 47.4545C324.933 44.4619 324.517 41.3154 324.681 38.1721C337.641 34.7234 352.716 47.5557 352.716 47.5557L371.637 32.7342L376.824 40.3455Z"
                fill="#FE5C45"
            />
            <path
                d="M317.84 107.642C313.33 106.084 308.667 104.473 303.637 103.868L303.421 103.842L303.481 103.633C308.688 85.3507 307.388 73.6556 304.836 56.0879C304.294 52.2985 304.991 48.4353 306.824 45.0748C308.657 41.7143 311.528 39.0367 315.008 37.4417L315.039 37.4277L329.405 36.5148L329.438 36.5128L339.886 39.5858C341.389 40.0311 342.705 40.957 343.631 42.2214C344.558 43.4859 345.044 45.0193 345.016 46.5867C344.903 52.3642 345.143 58.8145 345.397 65.6435C345.967 80.9639 346.557 96.8059 343.211 107.085L343.194 107.139L343.149 107.174C340.073 109.678 336.22 111.032 332.254 111.001C327.564 111.001 322.778 109.348 317.84 107.642Z"
                fill="#FE5C45"
            />
        </svg>
    );
};
