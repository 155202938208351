import { useState } from "react";

export async function checkPermissions(
    namespace: string,
    relationTuples: {
        /* Expand these enums if you want to check more objects/relations */
        object: "general";
        relation: "read" | "write";
    }[]
): Promise<boolean[]> {
    const res = await fetch(`${import.meta.env.VITE_AUTH_SIDECAR_API_URL}/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    const data: boolean[] = await res.json();
    return Array.isArray(data) ? data : Array(relationTuples.length).fill(false);
}

type Relation = Parameters<typeof checkPermissions>[1][0];

export function usePermission(namespace: string, relation: Relation) {
    const [hasPermission, setHasPermission] = useState<boolean | undefined>(undefined);
    checkPermissions(namespace, [relation])
        .then((res) => {
            setHasPermission(res[0]);
        })
        .catch((err) => {
            console.error(err);
            setHasPermission(false);
        });
    return hasPermission;
}
