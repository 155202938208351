import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Pen, Search, Trash } from "@ignite-analytics/icons";
import { Alert, Chip, Grid, Stack, TextField, Typography } from "@mui/material";
import {
    DataGridPro,
    gridClasses,
    GridCellParams,
    GridColDef,
    GridValueFormatterParams,
    useGridApiRef,
    GridActionsCellItem,
    GridRowParams,
    GridFilterModel,
    GridLogicOperator,
} from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RolesChip } from "@/components/RolesChip";
import { useAlert } from "@/contexts/Alerts";
import { RoleUser } from "@/gql/graphql";
import { useCurrentUser } from "@/hooks/users/hooks";
import { getUsersQuery, removeUsersMutation, setUserRolesMutation } from "@/hooks/users/queries";
import { RoleKey } from "@/interfaces/roles";

import { EditUserDrawer } from "./EditUserDrawer";
import { ConfirmRemoveUserDialog } from "./EditUserDrawer/ConfirmRemoveUserDialog";

export function mapQueryError(error: ApolloError) {
    if (error.message === "7 PERMISSION_DENIED: Permission denied") {
        return <FormattedMessage defaultMessage="You do not have permission to perform this action" />;
    }
    return <FormattedMessage defaultMessage="An error occurred" />;
}

type Props = {
    canAdministrate?: boolean;
};

type DataRow = RoleUser & {
    tags: string[];
    roles: RoleKey[];
};

export function UsersList({ canAdministrate }: Props) {
    const { formatMessage } = useIntl();
    const { id: uid } = useCurrentUser();
    const ignoreScimmed = useFeatureToggle("ignore-scimmed", false);
    const { alertUser } = useAlert();

    const { data, loading, error, refetch } = useQuery(getUsersQuery, { variables: { input: { terms: "" } } });
    // reload user query when mutate
    const [removeUser] = useMutation(removeUsersMutation, { onCompleted: refetch });
    const [updateRoles] = useMutation(setUserRolesMutation, { onCompleted: refetch });

    const users: DataRow[] = useMemo(() => {
        if (!data) return [];
        return data.getUsers.result
            .map((user) => {
                const tags: string[] = [];
                if (user.scimmed && !ignoreScimmed) {
                    tags.push(formatMessage({ defaultMessage: "Managed by Azure" }));
                }
                return { ...user, tags, roles: user.roles as RoleKey[] };
            })
            .sort((a, b) => {
                if (a.id === uid) return -1;
                if (b.id === uid) return 1;
                return a.fullName.localeCompare(b.fullName);
            });
    }, [data, ignoreScimmed, formatMessage, uid]);

    const apiRef = useGridApiRef();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
    const [confirmRemoveUserDialogOpen, setConfirmRemoveUserDialogOpen] = useState<null | { userId: string }>(null);
    const [userDetailDrawerOpen, setUserDetailDrawerOpen] = useState<RoleUser | null>(null);

    async function handleRemoveUser(userId: string) {
        const res = await removeUser({ variables: { input: { userIds: [userId] } } });
        if (!res.errors) {
            alertUser({
                value: formatMessage({ defaultMessage: "User has been removed" }),
                severity: "success",
            });
        } else {
            alertUser({
                value: formatMessage({ defaultMessage: "Failed to remove user" }),
                severity: "error",
            });
        }
        setUserDetailDrawerOpen(null);
    }
    async function handleUpdateRoles(userId: string, roles: RoleKey[]) {
        const res = await updateRoles({ variables: { input: { userId, roleNames: roles } } });
        if (!res.errors) {
            alertUser({
                value: formatMessage({ defaultMessage: "Roles updated" }),
                severity: "success",
            });
        } else {
            alertUser({
                value: formatMessage({ defaultMessage: "Failed to update roles" }),
                severity: "error",
            });
        }
        setUserDetailDrawerOpen(null);
    }

    function canDelete(id: string, scimmed: boolean) {
        if (id == uid) return true;
        if (scimmed) return false;
        if (!canAdministrate) return false;
        return true;
    }

    const columns: GridColDef[] = [
        {
            field: "email",
            headerName: formatMessage({ defaultMessage: "User" }),
            flex: 4,
            renderCell: (params: GridCellParams<DataRow>) => {
                const { email, fullName, id } = params.row;
                return (
                    <Stack key={id} direction="column">
                        <Typography variant="subtitle2">{fullName}</Typography>
                        <Typography variant="caption">{email}</Typography>
                    </Stack>
                );
            },
        },
        { flex: 0, field: "name" }, // declare column for filtering, but hide it (see columnVisibilityModel)
        {
            field: "tags",
            type: "string",
            flex: 1,
            headerName: formatMessage({ defaultMessage: "Properties" }),
            filterOperators: [],
            renderCell: (params: GridCellParams<DataRow>) => {
                const tags = params.row.tags;
                return (
                    <Stack direction="row" flexWrap="wrap" spacing={1}>
                        {tags.map((tag) => (
                            <Chip key={tag} label={tag} variant="status" size="small" />
                        ))}
                    </Stack>
                );
            },
        },
        {
            field: "roles",
            headerName: formatMessage({ defaultMessage: "Roles" }),
            // flex: 1,
            flex: 1.5,
            filterOperators: [],
            renderCell: (params: GridCellParams<DataRow>) => <RolesChip max={1} roles={params.row.roles} />,
        },
        {
            field: "lastActivity",
            flex: 1,
            headerName: formatMessage({ defaultMessage: "Last sign in" }),
            type: "date",
            valueFormatter: (params: GridValueFormatterParams) => {
                if (!params.value) return undefined;
                return dayjs(params.value).format("LLL");
            },
            renderCell: (params: GridCellParams<DataRow>) => {
                const row = params.row;
                if (!row.lastActivity)
                    return (
                        <Typography variant="caption">
                            <FormattedMessage defaultMessage="N/A" />
                        </Typography>
                    );
                return <Typography variant="caption">{formatLastActivity(row.lastActivity)}</Typography>;
            },
        },
        {
            field: "actions",
            flex: 1,
            type: "actions",
            headerName: formatMessage({ defaultMessage: "Actions" }),
            getActions: (params: GridRowParams<DataRow>) => {
                if (!canDelete(params.row.id, params.row.scimmed || false)) return [];
                return [
                    <GridActionsCellItem
                        key="delete"
                        disabled={params.row.id == uid ? false : !canAdministrate}
                        label={formatMessage({ defaultMessage: "Edit" })}
                        data-testid={`user-row-edit-${params.row.id}`}
                        icon={<Pen />}
                        onClick={() => {
                            setUserDetailDrawerOpen(params.row);
                        }}
                    />,
                    <GridActionsCellItem
                        key="delete"
                        disabled={params.row.id == uid ? false : !canAdministrate}
                        label={formatMessage({ defaultMessage: "Edit" })}
                        data-testid={`user-row-edit-${params.row.id}`}
                        icon={<Trash />}
                        onClick={() => {
                            setConfirmRemoveUserDialogOpen({ userId: params.row.id });
                        }}
                    />,
                ];
            },
        },
    ];

    if (error) {
        const msg = mapQueryError(error);
        return (
            <Stack direction="row" justifyContent="center" p={5}>
                <Alert severity="error">{msg}</Alert>
            </Stack>
        );
    }

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Stack direction="row" gap={8}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            label={formatMessage({ defaultMessage: "Search users" })}
                            size="small"
                            onChange={(e) => {
                                const value = e.target.value;
                                setFilterModel({
                                    logicOperator: GridLogicOperator.Or,
                                    items: [
                                        { id: "name", field: "name", operator: "contains", value },
                                        { id: "email", field: "email", operator: "contains", value },
                                    ],
                                });
                            }}
                            sx={{ width: "300px" }}
                            InputProps={{
                                endAdornment: <Search fontSize="small" />,
                            }}
                        />
                    </Grid>
                </Grid>
            </Stack>

            <Stack minWidth="100%" sx={{ background: "white" }}>
                <DataGridPro
                    disableRowSelectionOnClick
                    disableMultipleRowSelection
                    density="comfortable"
                    rows={users}
                    autoHeight
                    columnVisibilityModel={{ name: false }}
                    loading={loading}
                    filterModel={filterModel}
                    disableColumnFilter
                    apiRef={apiRef}
                    sx={{
                        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                            outline: "none",
                        },
                        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                            outline: "none",
                        },
                    }}
                    columns={columns}
                    isCellEditable={() => false}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                />
            </Stack>

            <ConfirmRemoveUserDialog
                open={confirmRemoveUserDialogOpen !== null}
                onClose={() => {
                    setConfirmRemoveUserDialogOpen(null);
                    setUserDetailDrawerOpen(null);
                }}
                onConfirm={async () => {
                    if (confirmRemoveUserDialogOpen !== null) {
                        await handleRemoveUser(confirmRemoveUserDialogOpen.userId);
                    }
                    setConfirmRemoveUserDialogOpen(null);
                }}
            />
            <EditUserDrawer
                open={userDetailDrawerOpen !== null}
                onClose={() => setUserDetailDrawerOpen(null)}
                user={userDetailDrawerOpen}
                canChangeRoles={canAdministrate}
                canRemove={canAdministrate || userDetailDrawerOpen?.id == uid}
                onEditRoles={handleUpdateRoles}
                onRemove={handleRemoveUser}
            />
        </Stack>
    );
}
function formatLastActivity(ts: string | undefined) {
    if (ts === undefined) {
        return "-";
    }
    const isOneMonthOrLess = dayjs().diff(dayjs(ts), "month") <= 1;
    if (isOneMonthOrLess) {
        return dayjs(ts).fromNow();
    }
    return dayjs(ts).format("DD MMM YYYY");
}
