import { useMutation, useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { AccessHistory, Consent } from "@/interfaces/support-access";

export const listQuery = graphql(`
    query listConsents {
        listConsents {
            id
            approvedAt
            expiresAt
            revokedAt
            approvedBy {
                id
                displayName
            }
        }
    }
`);

export const listAccessesQuery = graphql(`
    query listAccessRequests($limit: Int!, $offset: Int!) {
        listAccessRequests(limit: $limit, offset: $offset) {
            data {
                id
                user {
                    id
                    displayName
                }
                approvedAt
                createdAt
                grantedAt
                revokedAt
                durationHours
                consentId
                reason
            }
            total
        }
    }
`);

const grantMutation = graphql(`
    mutation grantSupportAccess($validHours: Int!) {
        grantSupportAccess(validHours: $validHours) {
            id
        }
    }
`);
const revokeMutation = graphql(`
    mutation revokeSupportAccess {
        revokeSupportAccess {
            id
        }
    }
`);

export function useSupportAccess(resultsPerPage: number) {
    const lst = useQuery<{ listConsents: Consent[] }>(listQuery, {
        variables: { input: {} },
    });
    const accesses = useQuery<{ listAccessRequests: { data: AccessHistory[]; total: number } }>(listAccessesQuery, {
        variables: { limit: resultsPerPage, offset: 0 },
    });
    const [mutGrant, grantResult] = useMutation(grantMutation);
    const [mutRevoke, revokeResult] = useMutation(revokeMutation);

    return {
        grant: async (days: number) => {
            await mutGrant({ variables: { validHours: days * 24 } });
            await lst.refetch();
        },
        revoke: async () => {
            await mutRevoke();
            await lst.refetch();
        },
        fetchPage: async (page: number) => {
            accesses.refetch({ limit: resultsPerPage, offset: page * resultsPerPage });
        },
        consents: lst.data?.listConsents || [],
        dataLoading: lst.loading,
        loading: grantResult.loading || revokeResult.loading || lst.loading || accesses.loading,
        error: lst.error || grantResult.error || revokeResult.error || accesses.error,
        accesses: accesses.data?.listAccessRequests?.data || [],
        totalAccesses: accesses.data?.listAccessRequests?.total || 0,
    };
}
