import { DotsVertical, Search } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Alert,
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    TextField,
    Menu,
    MenuItem,
    IconButton,
    ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as React from "react";
import { useIntl, MessageDescriptor } from "react-intl";

import { DeleteDialog } from "@/components/DeleteDialog";
import { RolesChip } from "@/components/RolesChip";
import { useAlert } from "@/contexts/Alerts";
import { useInvites } from "@/hooks/invites";
import { SparseUserInvite, UserInvite } from "@/interfaces/invite";

import { EditInviteDialog } from "../EditInviteDialog";
import { InviteDialog } from "../InviteDialog";

import { mapQueryError } from "./helpers";
import messages from "./messages";

dayjs.extend(relativeTime);

interface Props {
    openModal: boolean;
    closeModal: () => void;
    canAdministrate: boolean;
}

export function renderStatus(
    invite: UserInvite,
    formatMessage: (descriptor: MessageDescriptor, values?: Record<string, string>) => string
) {
    const { status } = invite;
    let label = "";
    let color: "primary" | "secondary" | "error" | "success" | "info" = "primary";
    if (status === "COMPLETE") {
        label = "Complete";
        color = "success";
    } else if (status == "EXPIRED") {
        label = formatMessage(messages.chipInactive);
        color = "error";
    } else if (status === "PROCESSING" || status == "EMAIL_SENT" || status == undefined) {
        color = "primary";
        label = formatMessage(messages.chipEmailSent);
    }
    return <Chip size="small" variant="status" color={color} label={label} />;
}

type RowProps = {
    invite: UserInvite;
    canAdministrate: boolean;
    resendInvite: () => Promise<void>;
    openEditDialog: () => void;
    openDeleteDialog: () => void;
};
const Row = ({ invite, canAdministrate, resendInvite, openEditDialog, openDeleteDialog }: RowProps) => {
    const { formatMessage } = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    return (
        <TableRow key={invite.id} data-testid={`invite-row-${invite.id}`}>
            <TableCell>
                <Typography variant="subtitle2">{invite.email}</Typography>
            </TableCell>
            <TableCell>
                <RolesChip max={5} roles={invite.roles} />
            </TableCell>
            <TableCell>{renderStatus(invite, formatMessage)}</TableCell>
            <TableCell align="right">
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(true);
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <DotsVertical fontSize="small" />
                </IconButton>
            </TableCell>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                PaperProps={{ style: { width: "180px" } }}
                onClose={() => {
                    setMenuOpen(false);
                }}
            >
                <MenuItem
                    disabled={!canAdministrate}
                    onClick={() => {
                        resendInvite();
                        setMenuOpen(false);
                    }}
                >
                    <ListItemText>{formatMessage(messages.resendInvite)}</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={!canAdministrate}
                    onClick={() => {
                        openEditDialog();
                        setMenuOpen(false);
                    }}
                >
                    <ListItemText>{formatMessage(messages.edit)}</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={!canAdministrate}
                    onClick={() => {
                        openDeleteDialog();
                        setMenuOpen(false);
                    }}
                >
                    <ListItemText>{formatMessage(messages.delete)}</ListItemText>
                </MenuItem>
            </Menu>
        </TableRow>
    );
};

export function InviteList({ canAdministrate, openModal, closeModal }: Props) {
    const { formatMessage } = useIntl();
    const [selected, setSelected] = React.useState<UserInvite | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const { alertUser } = useAlert();
    const {
        invites,
        loading,
        dataLoading,
        searchTerm,
        inviteUser,
        resendInvite,
        deleteInvite,
        editRoles,
        reset,
        error,
    } = useInvites();

    async function onCreateSubmit({ email, roles }: SparseUserInvite) {
        track("invite submit");
        const res = await inviteUser({ email, roles });
        if (res.error == null) {
            alertUser({ value: formatMessage(messages.createdInvite), severity: "success" });
        }
        closeModal();
    }
    async function onResendInvite(inviteId: string) {
        track("resend invite");
        const ok = await resendInvite(inviteId);
        if (ok) {
            alertUser({ value: formatMessage({ defaultMessage: "Resent invite" }), severity: "success" });
        } else {
            alertUser({ value: formatMessage(messages.rateLimitExceeded), severity: "error" });
        }
    }

    async function onDeleteInvite(inviteId: string) {
        track("invite delete");
        const ok = await deleteInvite(inviteId);
        setDeleteDialogOpen(false);
        if (ok) {
            alertUser({ value: formatMessage({ defaultMessage: "Removed invitation" }), severity: "success" });
        }
    }

    async function onEditInvite({ id, roles }: { id: string; roles: string[] }) {
        track("invite edit");
        const ok = await editRoles(id, roles);
        setEditDialogOpen(false);
        if (ok) {
            alertUser({ value: formatMessage(messages.editInviteSuccess), severity: "success" });
        }
    }

    return (
        <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <TextField
                    label={formatMessage({ defaultMessage: "Search invites" })}
                    size="small"
                    value={searchTerm.value}
                    onChange={(e) => searchTerm.set(e.target.value)}
                    sx={{ width: "300px" }}
                    InputProps={{
                        endAdornment: <Search fontSize="small" />,
                    }}
                />
            </Stack>
            {error && (
                <Alert severity="error" onClose={reset}>
                    {formatMessage(messages[mapQueryError(error)])}
                </Alert>
            )}
            {!canAdministrate && !loading && (
                <Alert severity="info">{formatMessage(messages.administratorsOnly)}</Alert>
            )}
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="invites table" data-testid="invites-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{formatMessage(messages.email)}</TableCell>
                            <TableCell>{formatMessage(messages.roles)}</TableCell>
                            <TableCell>{formatMessage(messages.status)}</TableCell>
                            <TableCell align="right">{formatMessage(messages.actions)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invites.map((invite) => (
                            <Row
                                key={invite.id}
                                invite={invite}
                                canAdministrate={canAdministrate}
                                resendInvite={() => onResendInvite(invite.id)}
                                openEditDialog={() => {
                                    setSelected(invite);
                                    setEditDialogOpen(true);
                                }}
                                openDeleteDialog={() => {
                                    setSelected(invite);
                                    setDeleteDialogOpen(true);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>

                {dataLoading && (
                    <Stack direction="row" justifyContent="center" p={5}>
                        <CircularProgress />
                    </Stack>
                )}

                {invites.length === 0 && !dataLoading && (
                    <Stack p={4} alignItems="center">
                        <Typography>{formatMessage(messages.noInvites)}</Typography>
                    </Stack>
                )}
            </TableContainer>

            <DeleteDialog
                title={formatMessage(messages.deleteDialogTitle)}
                loading={loading}
                description={formatMessage(messages.deleteConfirm, { email: selected?.email })}
                onClose={() => setDeleteDialogOpen(false)}
                onDelete={() => selected && onDeleteInvite(selected.id)}
                open={deleteDialogOpen && !!selected}
            />

            <InviteDialog open={openModal} onSubmit={onCreateSubmit} onClose={closeModal} />

            <EditInviteDialog
                open={editDialogOpen && !!selected}
                loading={loading}
                onSubmit={onEditInvite}
                onClose={() => {
                    track("cancel edit invite");
                    setEditDialogOpen(false);
                }}
                invite={selected}
            />
        </Stack>
    );
}
