import { defineMessages } from "react-intl";

const messages = defineMessages({
    actions: {
        defaultMessage: "Actions",
        id: "invitelist.actions",
    },
    lastEmailSentAt: {
        defaultMessage: "Last email sent at",
        id: "invitelist.lastEmailSentAt",
    },
    createdAt: {
        defaultMessage: "Created at",
        id: "invitelist.createdAt",
    },
    details: {
        defaultMessage: "Details",
        id: "invitelist.details",
    },
    chipEmailSent: {
        defaultMessage: "Email sent",
        id: "invitelist.chipEmailSent",
    },
    administratorsOnly: {
        defaultMessage: "Only administrators of this workspace can manage invites.",
        id: "invitelist.administratorsOnly",
    },
    editInviteSuccess: {
        defaultMessage: "Roles have been updated.",
        id: "editInviteSuccess",
    },
    sent: {
        defaultMessage: "Sent",
        id: "sent",
    },
    invitationCreationFailed: {
        defaultMessage: "Failed to create invite",
    },
    createdInvite: {
        defaultMessage: "Created invite",
    },
    inviteAlreadyExists: {
        defaultMessage: "An invite already exists for this user",
    },
    tokenExpired: {
        defaultMessage: "Your credentials have expired. Please refresh the page",
    },
    rateLimitExceeded: {
        defaultMessage: "You can only resend invites every 5 minutes",
    },
    resendInvite: {
        defaultMessage: "Resend invite",
    },
    edit: {
        defaultMessage: "Edit",
    },
    delete: {
        defaultMessage: "Delete",
    },
    deleteConfirm: {
        defaultMessage: "Delete invitation to {email}?",
    },
    userAlreadyBelongsToCustomer: {
        defaultMessage: "User already belongs to this workspace.",
    },
    deleteDialogTitle: {
        defaultMessage: "Are you sure you want to proceed?",
    },
    genericError: {
        defaultMessage: "Something went wrong",
    },
    invitesResentSuccess: {
        defaultMessage: "Invites has been sent",
    },
    email: {
        defaultMessage: "Email",
    },
    roles: {
        defaultMessage: "Roles",
    },
    status: {
        defaultMessage: "Status",
    },
    invitedBy: {
        defaultMessage: "Invited by",
    },

    chipPending: {
        defaultMessage: "Pending",
    },
    chipInactive: {
        defaultMessage: "Inactive",
    },
    expiredAtDescription: {
        defaultMessage: "This invite expired at {expiredAt}",
    },
    noInvites: {
        defaultMessage:
            "There are currently no invites to display. Press the invite user button to invite a user to the platform",
    },
    selected: {
        defaultMessage: "{count, plural, =0 {{total} rows in total} =1{1 row selected} other {{count} rows selected}}",
    },
});

export default messages;
