import { useState, useEffect, useCallback } from "react";

import { ApiToken, ApiTokenInput } from "@/interfaces/tokens";

export function useApiTokens() {
    const [newToken, setNewToken] = useState<string>();
    const [tokens, setTokens] = useState<ApiToken[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingOne, setLoadingOne] = useState<string>("");
    const [error, setError] = useState<Error>();

    const _fetchTokens = useCallback(() => {
        setLoading(true);
        fetch(`${import.meta.env.VITE_AUTH_SIDECAR_API_URL}/token/state`, {
            method: "GET",
            credentials: "include",
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then(setTokens);
                } else {
                    res.json().then(setError);
                }
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [setTokens, setLoading, setError]);

    useEffect(_fetchTokens, [_fetchTokens]);

    function onToggleActive(token: ApiToken) {
        setLoadingOne(token.uuid);
        return fetch(`${import.meta.env.VITE_AUTH_SIDECAR_API_URL}/token/state`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(token.uuid),
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((tokenState: ApiToken) => {
                        setTokens(
                            tokens.map((token) => {
                                return token.uuid === tokenState.uuid ? tokenState : token;
                            })
                        );
                    });
                } else {
                    res.json().then(setError);
                }
            })
            .catch(setError)
            .finally(() => setLoadingOne(""));
    }

    function onDelete(uuid: string) {
        setLoadingOne(uuid);
        return fetch(`${import.meta.env.VITE_AUTH_SIDECAR_API_URL}/token/state`, {
            method: "DELETE",
            credentials: "include",
            body: JSON.stringify(uuid),
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then((deleted: boolean) => {
                        if (deleted) {
                            setTokens(tokens.filter((token) => token.uuid != uuid));
                        }
                    });
                } else {
                    res.json().then(setError);
                }
            })
            .catch(setError)
            .finally(() => setLoadingOne(""));
    }

    function onCreate(tokenData: ApiTokenInput) {
        setLoading(true);
        return fetch(`${import.meta.env.VITE_AUTH_SIDECAR_API_URL}/token/generate`, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(tokenData),
        })
            .then((res) => {
                if (res.ok) {
                    res.json().then(setNewToken);
                    _fetchTokens();
                } else {
                    res.json().then(setError);
                }
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }

    return { tokens, error, loading, loadingOne, onToggleActive, onDelete, onCreate, newToken, setNewToken };
}
