import { Trash } from "@ignite-analytics/icons";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useIntl } from "react-intl";

import { DeleteDialog } from "@/components/DeleteDialog";
import { RolesChip } from "@/components/RolesChip";
import { useApiTokens } from "@/hooks/tokens";
import { ApiTokenInput } from "@/interfaces/tokens";

import { CopyTokenDialog } from "./CopyTokenDialog";
import { CreateDialog } from "./CreateDialog";
import messages from "./messages";

export function ApiTokensPage() {
    const [deleteTokenId, setDeleteTokenId] = useState<string>();
    const [createDialog, setCreateDialog] = useState(false);

    const { formatMessage } = useIntl();
    const { newToken, setNewToken, tokens, loading, loadingOne, error, onToggleActive, onCreate, onDelete } =
        useApiTokens();

    if (error) {
        return (
            <Stack direction="row" justifyContent="center" p={5}>
                <Alert severity="error">{formatMessage(messages.error)}</Alert>
            </Stack>
        );
    }

    function handleDelete() {
        if (deleteTokenId) {
            onDelete(deleteTokenId)?.then(() => setDeleteTokenId(undefined));
        }
    }

    function handleCreate(data: ApiTokenInput) {
        onCreate(data).then(() => {
            setCreateDialog(false);
        });
    }

    return (
        <Container disableGutters={false} sx={{ mx: 0 }} maxWidth="lg">
            <Stack py={2}>
                <Typography variant="h6">{formatMessage(messages.subTitle)}</Typography>
                <Typography variant="body2">{formatMessage(messages.description)}</Typography>

                <Stack py={2} direction="row">
                    <Button onClick={() => setCreateDialog(true)}>{formatMessage(messages.createButton)}</Button>
                </Stack>

                <TableContainer component="div">
                    {loading && (
                        <Stack p={3} direction="row" justifyContent="center">
                            <CircularProgress />
                        </Stack>
                    )}
                    {!loading && (
                        <Table aria-label="api tokens table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{formatMessage(messages.nameHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.secretHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.rolesHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.lastUsedHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.createdHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.expiringInHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.enabledHeader)}</TableCell>
                                    <TableCell>{formatMessage(messages.deleteHeader)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tokens?.map((token) => (
                                    <TableRow key={token.uuid} data-testid={`token-row-${token.uuid}`}>
                                        <TableCell component="th" scope="row">
                                            {token.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {token.stub}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <RolesChip max={2} roles={token.roles} />
                                        </TableCell>
                                        <TableCell>
                                            {token.lastUsed
                                                ? dayjs(token.lastUsed).fromNow()
                                                : formatMessage(messages.never)}
                                        </TableCell>
                                        <TableCell>{dayjs(token.createdAt).format("LLL")}</TableCell>
                                        <TableCell>
                                            {token.expiresAt
                                                ? dayjs(token.expiresAt).fromNow()
                                                : formatMessage(messages.never)}
                                        </TableCell>
                                        {loadingOne === token.uuid ? (
                                            <TableCell align="center" colSpan={2}>
                                                <Stack p={1} direction="row" justifyContent="center">
                                                    <CircularProgress size={18} />
                                                </Stack>
                                            </TableCell>
                                        ) : (
                                            <>
                                                <TableCell>
                                                    <Tooltip
                                                        title={formatMessage(
                                                            token.active ? messages.active : messages.inactive
                                                        )}
                                                    >
                                                        <Checkbox
                                                            onChange={() => {
                                                                onToggleActive(token);
                                                            }}
                                                            checked={token.active}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => setDeleteTokenId(token.uuid)}
                                                        size="small"
                                                    >
                                                        <Trash fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                    {tokens.length === 0 && !loading && (
                        <Stack py={5} alignItems="center">
                            <Typography>{formatMessage(messages.noTokens)}</Typography>
                        </Stack>
                    )}
                </TableContainer>

                {/* Delete dialog */}
                <DeleteDialog
                    onDelete={() => handleDelete()}
                    loading={loading}
                    title={formatMessage(messages.deleteTitle)}
                    description={formatMessage(messages.deleteDescription)}
                    open={!!deleteTokenId}
                    onClose={() => setDeleteTokenId(undefined)}
                />

                {/* Create dialog for new tokens */}
                <CreateDialog
                    open={createDialog}
                    loading={loading}
                    onSubmit={handleCreate}
                    onClose={() => setCreateDialog(false)}
                />

                <CopyTokenDialog token={newToken} open={!!newToken && !error} onClose={() => setNewToken(undefined)} />
            </Stack>
        </Container>
    );
}
