import * as Sentry from "@sentry/react";
import { useEffect, useMemo, useState } from "react";

import { Color } from "./interfaces";

export function useColors() {
    const [colors, setColors] = useState<Color[]>([]);
    const [upstreamColors, setUpstreamColors] = useState<Color[]>([]);
    const [loading, setLoading] = useState(true);
    const customersUrl = import.meta.env.VITE_CUSTOMERS_API_URL;
    useEffect(() => {
        fetch(`${customersUrl}/colors`, {
            credentials: "include",
        })
            .then((res) => {
                const { status } = res;
                if (status === 200) {
                    res.json()
                        .then((data: { hex: string; id: number }[]) => {
                            setColors(data.map((d) => ({ id: d.id, hex: d.hex })));
                            setUpstreamColors(data);
                        })
                        .catch((err) => {
                            Sentry.captureMessage("Failed to parse colors", {
                                tags: { app: "company-settings" },
                                extra: { err },
                            });
                        });
                } else if (status === 404) {
                    setColors([]);
                } else {
                    Sentry.captureMessage("Failed to fetch colors", {
                        tags: { app: "company-settings" },
                        extra: { status },
                    });
                }
            })
            .finally(() => setLoading(false));
    }, [customersUrl]);

    const hasChanges = useMemo(() => {
        if (colors.length !== upstreamColors.length) {
            return true;
        }
        for (let i = 0; i < colors.length; i++) {
            if (colors[i].id !== upstreamColors[i].id) {
                return true;
            }
            if (colors[i].hex !== upstreamColors[i].hex) {
                return true;
            }
        }
        return false;
    }, [colors, upstreamColors]);
    function randomId() {
        // must be whole number
        return Math.floor(Math.random() * 1000000);
    }

    return {
        colors,
        loading,
        hasChanges,
        move(from: number, to: number) {
            const newColors = [...colors];
            const [removed] = newColors.splice(from, 1);
            newColors.splice(to, 0, removed);
            setColors(newColors);
        },
        insert(hex: string) {
            const color = { id: randomId(), hex };
            const newColors = [...colors];
            newColors.push(color);
            setColors(newColors);
        },
        remove(index: number) {
            const newColors = [...colors];
            newColors.splice(index, 1);
            setColors(newColors);
        },
        updateColorCode: (index: number, hex: string) => {
            const newColors = [...colors];
            newColors[index].hex = hex;
            setColors(newColors);
        },
        save: async () => {
            return fetch(`${customersUrl}/colors`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(colors),
            })
                .then((res) => {
                    if (res.status > 299) {
                        Sentry.captureMessage("Failed to save colors", {
                            tags: { app: "company-settings" },
                            extra: { status: res.status, body: colors },
                        });
                        return false;
                    }
                    // make a deep copy, otherwise the reference is the same -> hasChanges is always evaluated to false
                    setUpstreamColors(colors.map((c) => ({ ...c })));
                    return true;
                })
                .catch(() => false);
        },
    };
}
