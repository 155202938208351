import { Container, ContainerProps, styled } from "@mui/material";

const PageLayoutContainer = styled(Container)({
    "*:where(html[data-new-layout]) &": {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    "*:where(html:not([data-new-layout])) &": {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
});

/**
 * Page Layout behaves as a regular page. It grows with the content, and fills the available space.
 */
const PageLayout: React.FC<ContainerProps> = (props) => {
    return <PageLayoutContainer className="PageLayout" disableGutters={false} maxWidth="xl" {...props} />;
};

export { PageLayout };
