import { Drag, Trash } from "@ignite-analytics/icons";
import { Box, Card, CardContent, ClickAwayListener, IconButton, Stack, Typography } from "@mui/material";
import type { Identifier } from "dnd-core";
import React, { useState, useRef } from "react";
import { SketchPicker, ColorResult } from "react-color";
import { useDrag, useDrop } from "react-dnd";

import { ItemTypes } from "../constants";
import { Color } from "../interfaces";

interface Props {
    color: Color;
    index: number;
    onDelete: (id: number) => void;
    onMove: (dragIndex: number, hoverIndex: number) => void;
    onColorChange: (hex: string) => void;
    disabled?: boolean;
}

interface DragItem {
    id: string;
    index: number;
}

export function ColorsRow({ color, index, onDelete, onMove, onColorChange, disabled }: Props) {
    const [hovering, setHovering] = useState(false);
    const [offset, setOffset] = useState<number>();

    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.

            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        canDrag: !disabled,
        type: ItemTypes.CARD,
        item: () => {
            return { id: color.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    function handleColorChange(colorResult: ColorResult) {
        onColorChange(colorResult.hex);
    }

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        const rect = e.currentTarget.getBoundingClientRect();
        setOffset(rect.x);
    }

    return (
        <>
            <Card
                ref={ref}
                data-testid={`color-card-${index}`}
                data-handler-id={handlerId}
                sx={{ opacity, width: 250, cursor: "grab", display: "inline-block", mr: 1 }}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                elevation={3}
            >
                <CardContent onClick={handleClick}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Stack color="text.secondary" sx={{ visibility: disabled ? "hidden" : "visible" }}>
                                <Drag fontSize="small" color="inherit" />
                            </Stack>
                            <Box width={32} height={32} bgcolor={color.hex} />
                            <Typography variant="subtitle1">{color.hex}</Typography>
                        </Stack>
                        {!disabled && hovering && (
                            <IconButton onClick={() => onDelete(color.id)} size="small">
                                <Trash color="error" fontSize="inherit" />
                            </IconButton>
                        )}
                    </Stack>
                </CardContent>
            </Card>
            {!disabled && offset && (
                <ClickAwayListener onClickAway={() => setOffset(undefined)}>
                    <Stack position="absolute" left={offset}>
                        <SketchPicker onChange={(value) => handleColorChange(value)} color={color.hex} />
                    </Stack>
                </ClickAwayListener>
            )}
        </>
    );
}
