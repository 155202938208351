import { UserPlus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography, Container, Stack, Tab, Button } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/tools";

import { InviteList } from "./InviteList";
import messages from "./messages";
import { UsersList } from "./UsersList";

export function UsersPage() {
    const { formatMessage } = useIntl();
    const [createModal, setCreateModal] = useState(false);
    const [tab, setTab] = useState<"users" | "invites">("users");

    // Update breadcrumbs in base app
    useSetBreadcrumbs(formatMessage(messages.title));

    const canAdministrate = usePermission("customers", { object: "general", relation: "write" });

    function onTabChange(_: React.SyntheticEvent, newValue: "users" | "invites") {
        track("Users&Roles: tab changed", { tab: newValue });
        setTab(newValue);
    }

    return (
        <TabContext value={tab}>
            <Container data-testid="users-container" disableGutters={false} sx={{ mx: 0 }} maxWidth="lg">
                <Stack>
                    <Stack justifyContent="space-between" gap={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Stack direction="row" justifyContent="space-between" gap={3}>
                            <Typography variant="textLg" fontWeight="500">
                                <FormattedMessage defaultMessage="Users & roles" />
                            </Typography>
                            <Button
                                startIcon={<UserPlus />}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                data-testid="invite-user-button"
                                disabled={!canAdministrate}
                                onClick={() => {
                                    track("create invite button clicked");
                                    setCreateModal(true);
                                    setTab("invites");
                                }}
                            >
                                <FormattedMessage defaultMessage="Invite new user" />
                            </Button>
                        </Stack>
                        <TabList onChange={onTabChange} aria-label="users and invites tab">
                            <Tab label={formatMessage(messages.tabUsers)} value="users" />
                            <Tab label={formatMessage(messages.tabInvites)} value="invites" data-testid="invites-tab" />
                        </TabList>
                    </Stack>
                    <TabPanel sx={{ px: 0 }} value="users">
                        <UsersList canAdministrate={canAdministrate} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0 }} value="invites">
                        <InviteList
                            canAdministrate={!!canAdministrate}
                            openModal={createModal}
                            closeModal={() => setCreateModal(false)}
                        />
                    </TabPanel>
                </Stack>
            </Container>
        </TabContext>
    );
}
