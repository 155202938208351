import { graphql } from "@/gql";

export const getUsersQuery = graphql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            total
            result {
                id
                firstName
                lastName
                fullName
                initials
                email
                lastActivity
                roles
                scimmed
            }
        }
    }
`);

export const setUserRolesMutation = graphql(`
    mutation setUserRoles($input: SetUserRolesInput!) {
        setUserRoles(input: $input) {
            roleNames
        }
    }
`);

export const removeUsersMutation = graphql(`
    mutation removeUsers($input: RemoveUsersInput!) {
        removeUsers(input: $input) {
            userIds
            count
        }
    }
`);
