import { LoadingButton } from "@mui/lab";
import { Stack, Button, Drawer, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { RolesSelect } from "@/components/RolesSelect";
import { RoleUser } from "@/gql/graphql";
import { RoleKey } from "@/interfaces/roles";

import { ConfirmRemoveUserDialog } from "./ConfirmRemoveUserDialog";

export type UserDetailDrawerProps = {
    onClose: () => void;
    open: boolean;
    user: RoleUser | null;
    canRemove?: boolean;
    canChangeRoles?: boolean;
    onRemove: (userId: string) => Promise<void>;
    onEditRoles: (userId: string, newRoles: RoleKey[]) => Promise<void>;
};

export function EditUserDrawer({
    user,
    canChangeRoles,
    canRemove,
    onRemove,
    onClose,
    onEditRoles,
    open,
}: UserDetailDrawerProps) {
    const currentRoles = useMemo(() => (user?.roles ?? []) as RoleKey[], [user]);
    const [newRoles, setNewRoles] = useState<RoleKey[]>([]);
    const [loading, setLoading] = useState(false);
    const [confirmRemoveUserDialogOpen, setConfirmRemoveUserDialogOpen] = useState(false);
    const name = user?.firstName;
    const userId = user?.id;

    useEffect(() => {
        setNewRoles(currentRoles);
    }, [currentRoles]);

    const hasChanges = currentRoles.length !== newRoles.length || currentRoles.some((role) => !newRoles.includes(role));

    return (
        <>
            <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 400, px: 3 } }}>
                <Stack gap={2} pt={2} sx={{ height: "100%", position: "relative" }}>
                    {/* user name */}
                    <Stack justifyContent="start">
                        <Typography variant="h6">
                            <FormattedMessage defaultMessage="User details" />
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary">
                            {name}
                        </Typography>
                    </Stack>

                    {/* remove user */}
                    <Button
                        onClick={() => setConfirmRemoveUserDialogOpen(true)}
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2 }}
                        disabled={!canRemove}
                        fullWidth
                    >
                        <FormattedMessage defaultMessage="Remove user from workspace" />
                    </Button>

                    {/* permissions */}
                    <Stack>
                        <Typography variant="textXl" fontWeight="500">
                            <FormattedMessage defaultMessage="Roles" />
                        </Typography>
                        <RolesSelect disabled={!canChangeRoles} value={newRoles} onChange={setNewRoles} />
                    </Stack>

                    {/* bottom section  - fixed at bottom*/}
                    <Stack
                        justifySelf="flex-end"
                        position="sticky"
                        bottom={0}
                        left={0}
                        right={0}
                        sx={{ background: "white", zIndex: 1 }}
                        justifyContent="space-between"
                        alignItems="stretch"
                        direction="row"
                        mt="auto"
                        gap={1.5}
                        pb={2}
                        pt={1}
                    >
                        <Button fullWidth onClick={onClose} variant="text" color="secondary">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <LoadingButton
                            fullWidth
                            loading={loading}
                            variant="contained"
                            disabled={!hasChanges}
                            onClick={async () => {
                                if (!userId) return;
                                setLoading(true);
                                await onEditRoles(userId, newRoles).finally(() => setLoading(false));
                                onClose();
                            }}
                        >
                            <FormattedMessage defaultMessage="Save" />
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Drawer>

            <ConfirmRemoveUserDialog
                open={confirmRemoveUserDialogOpen}
                onClose={() => setConfirmRemoveUserDialogOpen(false)}
                onConfirm={() => {
                    if (userId == undefined) return Promise.resolve();
                    return onRemove(userId);
                }}
            />
        </>
    );
}
